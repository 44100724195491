import React, { useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import { AdminApis } from '../../apis/adminApi';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../reducer/loginSlice'

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RevenueCard = () => {
  const [see, setSee] = useState(false);
  const [userLists, setUserList] = React.useState([]);
    const [purchaseList, setPurchaseList] = React.useState([]);
      const userLoginData = useSelector((state) => state.data.login.value);
    const dispatch = useDispatch();
    //   const navigate = useNavigate();
  let [stat, setStat] = React.useState(null);

  React.useEffect(() => {
      AdminApis.getDashboardData().then(
        (response) => {
          if (response?.data) {
            setUserList(response?.data?.data?.purchase)
            setPurchaseList(response?.data?.data?.customers)
            // console?.log(response?.data?.data?.purchase)
            setStat(response?.data?.data)
          } else {
            dispatch(login([]))
          }
        }
      ).catch(function (error) {
  
      })
    }, []);

  if (stat === null) {
    return <div>Loading...</div>;
  }

  console.log(stat);

  const totalRevenue = stat?.totalRevenue;

  if (totalRevenue === undefined) {
    return <div>No data available for revenue</div>;
  }

  const data = {
    labels: ['Start', 'End'], // Labels representing the start and end points
    datasets: [
      {
        label: 'Revenue',
        data: [0, totalRevenue], // Line starts at 0 and ends at totalRevenue
        borderColor: '#0071BC', // Line color
        backgroundColor: 'rgba(255, 160, 0, 0.2)', // Area color (for fill)
        fill: true, // Fill the area below the line
        tension: 0.4, // Smooth curve
        borderWidth: 2, // Line thickness
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines
        },
      },
      y: {
        beginAtZero: true, // Start y-axis from 0
        max: totalRevenue, // Set dynamic max value for Y-axis based on totalRevenue
        grid: {
          display: true, // Show grid lines
        },
      },
  },
  };

  return (
    <div>
      <div className='bg-white rounded-xl shadow-lg px-[15px] pt-[20px] pb-[24px] border-[1px] border-[#dcdada] mt-5'>
        <span className="flex justify-between">
          <h5 className='text-xl font-bold'>Total Revenue</h5>
          <span className="cursor-pointer" onClick={() => setSee(!see)}>
            <FaEye />
          </span>
        </span>

        <div className='flex flex-col gap-4'>
          <h1 className='text-[16px] font-semibold'>
            {see ? (
              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(
                totalRevenue
              )
            ) : (
              'NGN *****'
            )}
          </h1>

          <div className='mt-2' style={{ width: '', height: '300px' }}>
            <Line data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueCard;

import React from 'react'

export default function CardPendingReview() {
  return (
    <div>
      <div className="bg-white mt-16">
        <div className="py-4 lg:px-10 px-6 ">
          <div className="py-3 font-[700] bg-[#0071BC] text-white p-2 rounded">
            <h1 className="text-xl font-semibold">Pending Reviews</h1>
          </div>
          <div className="border p-5">
            <div className="flex flex-col justify-center items-center py-20">
              <h2 className="font-bold text-base">
                You no orders waiting for review
              </h2>
              <h5 className="font-normal text-base text-gray-500 w-1/2 text-center leading-7">
              After getting your products delivered, you will be able to rate and review them. Your feedback will be published on the product page to help all Jumia's users get the best shopping experience!
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useRef, useState } from "react";
import { ProductsLiveApi } from "../apis/live/productsLiveApis";
import { Product } from "../types/products";
import { CartApis } from "../apis/cartApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { login } from "../reducer/loginSlice";
import {  FaChevronLeft, FaChevronRight, FaHeart, FaRegHeart } from "react-icons/fa";
import { useNavigate, Link, useLocation, } from "react-router-dom";
import Rating from "./Cards/CardRating";
import { addToCart } from "./Cards/AddtoCart";
import axios from "axios";
import { BsCart2 } from "react-icons/bs";

interface HomeProductInfo {
  id: string;
  product_name: string;
  product_image_1: string;
  product_price: number;
  discounted_price: number;
  is_under_deal: boolean;
}


interface HomeProductProps {
  homeProductInfo: HomeProductInfo;
}
const HomeProduct = ({homeProductInfo}: HomeProductProps) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [status, setStatus] = useState<"idle" | "loading" | "failed">("idle");
  const [error, setError] = useState<string | null>(null);
  const routeLocation = useLocation();
  const scrollContainerRefs = useRef<(HTMLDivElement | null)[]>([]);


  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({
    0: false,
    1: false,
    2: false,
  });

  const [activeStars, setActiveStars] = useState<{ [key: number]: boolean[] }>(
    {}
  );

  const scrollLeft = (index: number) => {
    if (scrollContainerRefs.current[index]) {
      scrollContainerRefs.current[index]!.scrollBy({
        left: -240,
        behavior: "smooth",
      });
    }
  };
  
  const scrollRight = (index: number) => {
    if (scrollContainerRefs.current[index]) {
      scrollContainerRefs.current[index]!.scrollBy({
        left: 240,
        behavior: "smooth",
      });
    }
  };
  

  // const handleStarClick = (productId: number, index: number) => {
  //   setActiveStars((prevStars) => ({
  //     ...prevStars,
  //     [productId]: prevStars[productId]?.map((star, starIndex) =>
  //       starIndex === index ? !star : star
  //     ) || [false, false, false, false, false], 
  //   }));
  // };


  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const userLoginData = useSelector((state: any) => state.data.login.value);
  const [userWishLists, setUserWishLists] = React.useState(new Set());

  
  const debugLocalStorage = () => {
    const savedRatings = localStorage.getItem(USER_RATINGS_KEY);
    console.log('Current localStorage ratings:', savedRatings);
  };

// Add this constant for localStorage key
const USER_RATINGS_KEY = 'user_product_ratings';

useEffect(() => {
  const fetchProducts = async () => {
    setStatus("loading");
    try {
      // Check localStorage at the start
      debugLocalStorage();
      
      const productApi = new ProductsLiveApi();
      const products = await productApi.fetchNewData();
      setProducts(products.data.data);
      setStatus("idle");

      // Create the initial ratings map based on average ratings
      const ratingsMap: { [key: number]: boolean[] } = {};
      products.data.data.forEach((product: Product) => {
        const rating = Math.round(product.average_rating || 0);
        ratingsMap[product.id] = Array(4).fill(false).map((_, i) => i < rating);
      });
      
      // Get user's personal ratings from localStorage
      const savedRatings = localStorage.getItem(USER_RATINGS_KEY);
      console.log('Retrieved from localStorage:', savedRatings);
        console.log('ratingsMap', ratingsMap)
  console.log('products', products.data.data)
      if (savedRatings) {
        try {
          const userRatings = JSON.parse(savedRatings);
          console.log('Parsed user ratings:', userRatings);
          
          // Override with user's personal ratings where available
          Object.keys(userRatings).forEach(productId => {
            const id = parseInt(productId);
            const userRating = userRatings[id];
            console.log(`Applying user rating for product ${id}: ${userRating}`);
            
            if (ratingsMap[id]) {
              ratingsMap[id] = Array(4).fill(false).map((_, i) => i < userRating);
            }
          });
        } catch (error) {
          console.error('Error parsing saved ratings:', error);
        }
      }
      
      console.log('Final ratingsMap:', ratingsMap);
      setActiveStars(ratingsMap);
    } catch (err) {
      setError("Failed to fetch products");
      setStatus("failed");
    }
  };
  fetchProducts();
}, []);

  React.useEffect(() => {
    
    CartApis.getAllList("")
      .then((response) => {
        if (response?.data) {
          const wishListIds = new Set(
            response?.data?.data.map((item: any) => item.item_id)
          );
          setUserWishLists(wishListIds);
        } else {
          dispatch(login([]));
        }
      })
      .catch(function (error) {
        console.error("Error fetching wishlist:", error);
      });
  }, []);

  const toggleWishlist = React.useCallback(
    (productInfos: any) => {
      if (!userLoginData?.id) {
        navigate("/sign-in");
        return;
      }

      const productId = productInfos?.id;
      const isWished = userWishLists.has(productId.toString());

      if (isWished) {
        // Remove from wishlist
        CartApis.deleteWishlist(productId)
          .then((response) => {
            if (response?.data) {
              setUserWishLists((prev) => {
                const updatedSet = new Set(prev);
                updatedSet.delete(productId);
                return updatedSet;
              });
              toast.success("Removed from wishlist.");
            }
          })
          .catch(function (error) {
            console.error("Error removing from wishlist:", error);
            toast.error("Error removing from wishlist.");
          });
      } else {
        // Add to wishlist
        let data = {
          product_id: productId,
          price: productInfos?.product_price,
          product_name: productInfos?.product_name,
          category: productInfos?.category,
          product_img: productInfos?.product_image_1
            ? productInfos?.product_image_1
            : "no image",
          user_id: userLoginData?.id,
        };
        CartApis.createWishlist(data)
          .then((response) => {
            if (response?.data) {
              setUserWishLists((prev) =>
                new Set(prev).add(productId.toString())
              );
              toast.success("Added to wishlist.");
            }
          })
          .catch(function (error) {
            console.error("Error adding to wishlist:", error);
            toast.error("Error adding to wishlist.");
          });
      }
    },
    [userLoginData, userWishLists, navigate]
  );

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     setStatus("loading");
  //     try {
  //       const productApi = new ProductsLiveApi();
  //       const products = await productApi.fetchNewData();
  //       setProducts(products.data.data);
  //       setStatus("idle");

  //       const ratingsMap: { [key: number]: boolean[] } = {};
  //       products.data.data.forEach((product: Product) => {
  //         const rating = Math.round(product.average_rating || 0); // Round rating
  //         ratingsMap[product.id] = Array(5).fill(false).map((_, i) => i < rating);
  //       });
  // console.log('ratingsMap', ratingsMap)
  // console.log('products', products.data.data)
  //       setActiveStars(ratingsMap);
  //     } catch (err) {
  //       setError("Failed to fetch products");
  //       setStatus("failed");
  //     }
  //   };
  //   fetchProducts();
  // }, []);


  // // Toggle expanded state for a specific row
  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const handleStarClick = async (productId: number, rating: number) => {
    if (!userLoginData?.token) {
      toast.error("You need to log in to submit a rating.");
      return;
    }
  
    const validRating = Math.min(5, Math.max(1, rating));
  
    try {
      // First update UI optimistically (for immediate feedback)
      setActiveStars((prevStars) => ({
        ...prevStars,
        [productId]: Array(4).fill(false).map((_, i) => i < validRating),
      }));
      
      // Get current localStorage data with error handling
      let userRatings = {};
      try {
        const savedRatings = localStorage.getItem(USER_RATINGS_KEY);
        if (savedRatings) {
          userRatings = JSON.parse(savedRatings);
        }
      } catch (error) {
        console.error('Error reading from localStorage:', error);
      }
      
      // Update user's rating
      userRatings = {
        ...userRatings,
        [productId]: validRating
      };
      
      // Save back to localStorage with error handling
      try {
        const ratingsString = JSON.stringify(userRatings);
        console.log('Saving to localStorage:', ratingsString);
        localStorage.setItem(USER_RATINGS_KEY, ratingsString);
        
        // Verify save was successful
        const verification = localStorage.getItem(USER_RATINGS_KEY);
        console.log('Verification from localStorage:', verification);
      } catch (error) {
        console.error('Error saving to localStorage:', error);
      }
      
      // Send rating to the backend
      await axios.post(
        `https://prod.mmartplus.com/api/products/${productId}/rate`,
        { rating: validRating },
        { headers: { Authorization: `Bearer ${userLoginData.token}` } }
      );
  
      toast.success("Product rated successfully.");
    } catch (error) {
      console.error("Error submitting rating:", error);
      toast.success("You have already rated this product");
    }
  };
  

// personal rating  
  // const handleStarClick = async (productId: number, rating: number) => {
  //   if (!userLoginData?.token) {
  //     toast.error("You need to log in to submit a rating.");
  //     return;
  //   }
  
  //   // Ensure the rating is between 1 and 5
  //   const validRating = Math.min(5, Math.max(1, rating));
  
  //   try {
  //     await axios.post(
  //       `https://prod.mmartplus.com/api/products/${productId}/rate`,
  //       { rating: validRating },
  //       { headers: { Authorization: `Bearer ${userLoginData.token}` } }
  //     );
  
  //     // Update UI state
  //     setActiveStars((prevStars) => ({
  //       ...prevStars,
  //       [productId]: Array(5).fill(false).map((_, i) => i < validRating),
  //     }));
      
  //     // Save user's rating to localStorage
  //     const savedRatings = localStorage.getItem(USER_RATINGS_KEY) || '{}';
  //     const userRatings = JSON.parse(savedRatings);
  //     userRatings[productId] = validRating;
  //     localStorage.setItem(USER_RATINGS_KEY, JSON.stringify(userRatings));
  
  //     toast.success("Product rated successfully.");
  //   } catch (error) {
  //     console.error("Error submitting rating:", error);
  //     toast.success("You have already rated this product");
  //   }
  // };
  

  if (status === "loading") return <div></div>;
  if (status === "failed") return <div>Error: {error}</div>;

  // Define number of items to show per row based on expanded state
  const getProductsToShow = (rowIndex: number, startIndex: number) => {
    const defaultCount = 10;
    const expandedCount = 10;

    const count = expandedRows[rowIndex] ? expandedCount : defaultCount;
    const productsForRow = products.slice(startIndex, startIndex + count);

    return productsForRow;
  };

  const rowSections = 3;
  const productsPerSection = Math.ceil(products.length / rowSections);
  
  const handleAddToCart = (product: any) => {
      addToCart(product, userLoginData, routeLocation, navigate, dispatch);
    };

  return (
    <div className="py-4 px-4 mt-10">
      <div className="div">
        {" "}
        <h3 className="font-bold text-3xl mb-5">Suggestions for you</h3>
      </div>
      {[0, 1, 2].map((sectionIndex) => {
        const startIndex = sectionIndex * productsPerSection;
        const productsToShow = getProductsToShow(sectionIndex, startIndex);

        return (
<div className="relative mb-8">
  {/* Left Arrow */}
  <button
onClick={() => scrollLeft(sectionIndex)}
    className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white shadow-lg p-2 rounded-full"
    aria-label="scroll left"
  >
    <FaChevronLeft className="text-black" />
  </button>

  <div className="overflow-x-auto scrollbar-none"  ref={(el) => (scrollContainerRefs.current[sectionIndex] = el)}>
    <div className="flex gap-4">
      {productsToShow.map((product) => (
                          <div
                    key={product.id}
                    className=" p-4 border rounded-lg shadow-md"
                    style={{
                      width: "240px",
                      flex: "0 0 auto",
                      height: "auto",
                    }}
                  >
                    <div style={{ height: "150px", overflow: "hidden" }}>
                      <span
                        onClick={() => toggleWishlist(product)}
                        className="flex justify-end cursor-pointer"
                      >
                        {userWishLists.has(product.id.toString()) ? (
                          <FaHeart className="text-blue-700" />
                        ) : (
                          <FaRegHeart className="text-blue-700" />
                        )}
                      </span>
                      <div className="flex flex-col items-center justify-center">

                      <Link to={`/view-product/${product.id}`}>
                        <img
                          src={product.product_image_1}
                          alt={product.product_name}
                          className=" h-ful h-40 rounded-md pb-4"
                          />
                          </Link>
                          </div>
                    </div>
                    <span
                      className="flex rounded-ful bg-[#027DC justify-left  cursor-pointer"
                      onClick={() => handleAddToCart(product)}
                      >
                      <div className="p-2 rounded-full bg-[#0071BC]">
                      <BsCart2 className="h-4 w-4" color="#fff" />
                      </div>
                        <h1 className="text-[#0071BC] items-center  justify-center flex ml-2">add to cart</h1>
                    </span>
                    <p className="text-black font-bold lg:mt-3 mt-3">
                    ₦{parseInt(product.product_price.replace('N', '')).toLocaleString()}
                    </p>
                    <p className="text-black font-bold lg:mt-2 mt-3 cursor-pointer">
                    <Link to={`/view-product/${product.id}`}>
                      {product.product_name.slice(0, 15)}...
                    </Link>
                    </p>
                    <div className="lg:pt-3 pt-3">

<Rating
  productId={product.id}
  activeStars={
    activeStars[product.id] || 
    Array(4).fill(false).map((_, i) => i < Math.round(product.average_rating || 0))
  }
  onStarClick={(index) => handleStarClick(product.id, index + 1)}
/>

                    </div>
                    <span className="cursor-pointer bg-blue-50  text-blue-700 w-40 mt-5 hidden sm:block">
                      <h2> 20 - 30mins delivery </h2>
                    </span>
                  </div>
      ))}
    </div>
  </div>

  {/* Right Arrow */}
  <button
onClick={() => scrollRight(sectionIndex)}
    className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white shadow-lg p-2 rounded-full"
    aria-label="scroll right"
  >
    <FaChevronRight className="text-black" />
  </button>
</div>

        );
      })}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default HomeProduct;
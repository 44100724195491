import React from "react";
import { AdminApis } from "../../apis/adminApi";
import { FaTrash, FaEdit, FaWhatsapp } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-awesome-modal";
import { useSelector, useDispatch } from "react-redux";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
// import { url } from "inspector";
import { store } from "../../store/store";
import { Oval } from "react-loader-spinner";
import configs from "../../configs";
import { Dispatch } from "redux";
import { login } from "../../reducer/loginSlice";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ProductApis } from "../../apis/productApis";

// components

export default function CardMiniStore() {
  const userLoginData = useSelector((state) => state.data.login.value);
  let [visible, setVisible] = React.useState(false);
  let [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
  let [toggleExceedModal, setToggleExceedModal] = React.useState(false);
  let [value, setvalue] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [contact, setContact] = React.useState("");
  let [effect, setEffect] = React.useState("");

  // console?.log(value)

  function toggleModal(value2, contact) {
    setvalue(value2);
    setContact(contact);
    setVisible(!visible);
  }

  React.useEffect(() => {
    if (!userLoginData?.id) {
      navigate("/sign-in");
    }
  });

  function toggleDelete(value2) {
    setvalue(value2);

    setToggleDeleteModal(!visible);
  }
  // console.log(contact)

  const [message, setMessage] = React.useState("");
  const [name, setName] = React.useState("");

  function isCopied() {
    toast.success("Copied to Clipboard");
  }

  const [loader, setLoader] = React.useState(true);
  function isCopied() {
    toast.success("Copied to Clipboard");
  }

  let [data, setdata] = React.useState([]);
  let [search, setSearch] = React.useState("");

  const location = useLocation();
  // const navigate = useNavigate();

  // Function to extract query params (e.g., page)
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  // Load data when the component mounts or page refreshes
  React.useEffect(() => {
    setLoader(true);
    const currentPage = getQueryParam("page") || ""; // Get the 'page' param from the URL or default to ''
    const searchQuery = getQueryParam("name") || ""; // Get the 'page' param from the URL or default to ''

    const query = {
      search: search ? search : searchQuery,
      name: "",
      categories: "",
    };

    ProductApis.getAllProducts(currentPage, query)
      .then((response) => {
        if (response?.data) {
          console.log(response?.data);
          setdata(response?.data);
          setLoader(false);
          // console.log(response?.data);
        } else {
          dispatch(login([]));
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoader(false);
      });
  }, [effect, location.search]); // Trigger effect when 'effect' or URL changes

  const paginator = React.useCallback(
    (value) => {
      //   setLoader(true);
      let value2 = 0;
      if (value !== null) {
        value2 = value;
      } else {
        value2 = 0;
      }

      const query = {
        search: search,
        name: "",
        categories: "",
      };
      setLoader(true);
      // Update the URL with the current page number
      //  console.log(value2)
      //  console.log(value)
      //  console.log(search)
      //  console.log(value2)

      if (search !== "") {
        navigate(`/admin/products?name=${search}`);
      } else if (search !== "" && value2 > 0) {
        navigate(`/admin/products?name=${search}?page=${value2}`);
      }
      //    else if (value2 && search === '') {
      // navigate(`/admin/products?page=${value2}`);

      //    }
      //  else if (value2 && search !== '') {
      //   navigate(`/admin/products?name=${search}?page=${value2}`);

      //  }
      else if (value2 > 0 && search === "") {
        navigate(`/admin/products?page=${value2}`);
      } else if (typeof value2 === "object" && search === "") {
        navigate(`/admin/products?name=${search}`);
      } else {
        navigate(`/admin/products`);
      }
      ProductApis.getAllProducts(value2, query)
        .then((response) => {
          if (response?.data) {
            setdata(response?.data);
            // console.log(response.data)
            setLoader(false);
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
        });
    },
    [data, loader, search]
  );

  // console.log(data?.data?.current_page)

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      // console?.log(message)
      data = {
        message: message,
        id: value?.id,
        name: value?.name,
        phone_number: value?.link_info?.phone_number,
      };
      AdminApis.editLink(data)
        .then((response) => {
          if (response?.data) {
            // console.log(response.data)
            setVisible(false);
            setEffect("");
            toast.success(response?.data?.message);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.data);
          toast.error("Offfline");
        })
        .finally(() => {
          //toast.error("No Internet Connection");
        });
    },
    [value, message, contact, name]
  );

  const deleteLink = React.useCallback(
    (e) => {
      e.preventDefault();
      setLoader(true);
      AdminApis.deleteProduct(value)
        .then((response) => {
          if (response?.data) {
            // console.log(response.data)
            setToggleDeleteModal(false);
            toast.success("Link Deleted Successfully");
            setLoader(false);
            setEffect("d");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.data);
          toast.error("Offfline");
        })
        .finally(() => {
          //toast.error("No Internet Connection");
        });
    },
    [value, message, effect]
  );

  const [searchTerm, setSearchTerm] = React.useState("");
  const inputEl = React.useRef("");
  const [searchResult, setSearchResult] = React.useState({ sa: "32" });

  const getSearchTerm = React.useCallback(() => {
    // console.log(inputEl.current.value);
    setSearchTerm(inputEl.current.value);
    if (searchTerm !== "") {
      const newContactList = data?.data?.data?.filter((data) => {
        return Object.values(data)
          .join(" ")
          ?.toLowerCase()
          ?.includes(inputEl?.current?.value?.toLowerCase());
      });
      setSearchResult(newContactList);
    } else {
      setSearchResult(data?.data?.data);
    }
  }, [inputEl, searchTerm, searchResult, data]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded">
        <div className="rounded-t mb-0  md:py-1 p-1 border-0">
          {/* <div className="bg-blue-100  rounded-lg ml-3 m-1 p-2 mb-3">
            <span className=" bg-blue-100  rounded-lg  text-gray-500 text-[12px]"><span className="mr-4 text-red-500 bg-red-200 p-1 px-3 rounded-full text-[15px]">!</span>Unlock a personalized shopping hub with Gupta's Mini Store. Tailor market links, showcase products, and enhance the shopping journey effortlessly.</span>
          </div> */}
          <div className="flex flex-wrap items-center">
            <div className="w-full px-4 max-w-full flex-grow flex-1">
              {data?.data?.data?.length ? (
                <span className="flex justify-between">
                  {/* <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label> */}
                  <div className="flex justify-start gap-2">
                    <div class="relative  visible">
                      <input
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        style={{ borderColor: "#0071BC" }}
                        id="default-search"
                        class="block p-4 pl-4 w-full h-4 text-sm text-gray-900 bg-gray-50 rounded-lg border focus:ring-blue-500 focus:border-blue-500 "
                        placeholder="Search "
                      />
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5 right-2.5 bottom-3 absolute text-gray-500 dark:text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </div>

                    <div>
                      <button
                        type="button"
                        onClick={paginator}
                        defaultValue={search}
                        style={{
                          backgroundColor: "#0071BC",
                          borderRadius: "3px",
                        }}
                        className=" text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-1.5 text-center "
                      >
                        Search
                      </button>
                    </div>
                  </div>

                  <NavLink
                    onClick={
                      userLoginData?.data?.no_of_mstore <= data?.data?.length
                        ? () => setToggleExceedModal(true)
                        : null
                    }
                    to={
                      userLoginData?.data?.no_of_mstore <= data?.data?.length
                        ? ""
                        : `/createproduct`
                    }
                    className="flex justify-center"
                  >
                    <span className="flex justify-center ">
                      <button
                        type="button"
                        style={{
                          backgroundColor: "#0071BC",
                          borderRadius: "50px",
                        }}
                        className=" text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-28 px-5 py-2.5 text-center "
                      >
                        + Add
                      </button>
                    </span>
                  </NavLink>
                </span>
              ) : (
                ""
              )}

<div>
  {!loader ? (
    searchResult?.length === 0 ? (
      <div
        className="p-2 shadow animate-pulse md:p-6 dark:border-gray-700"
        style={{ height: "70vh", width: "78vw" }}
      >
        <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-400">
          <span>Empty Record</span>
        </div>
      </div>
    ) : data?.data?.data?.length >= 1 ? (
      <div className="overflow-x-auto bg-white rounded-lg  border-gray-200 shadow-md mt-10">
        <table className="min-w-full table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="text-left p-4">Product Image</th>
              <th className="text-left p-4">Product Name</th>
              <th className="text-left p-4">Price</th>
              <th className="text-left p-4">Size</th>
              <th className="text-left p-4">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {(inputEl?.current?.value?.length > 1
              ? searchResult
              : data?.data?.data
            )?.map((datas) => (
              
              <tr key={datas?.id} className="hover:bg-blue-200 cursor-pointer">
                 <td className="p-4">
                  <NavLink
                    to={`/edit-product/${datas?.id}`}
                    state={{
                      search,
                      current_page: data?.data?.current_page,
                    }}
                  >
                    <div
                      className="bg-[#F4FBFF] h-12 w-8"
                      style={{
                        backgroundImage: `url(${datas?.product_image_1})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                    ></div>
                  </NavLink>
                </td>
                <td className="px-4">{datas?.product_name}</td>
                <td className="px-4">
                  {datas?.is_under_deal ? (
                    <>
                      <span className="line-through text-gray-500 hidden">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "NGN",
                        }).format(datas?.original_price)}
                      </span>{" "}
                      NGN{datas?.discounted_price}
                    </>
                  ) : (
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "NGN",
                    }).format(datas?.product_price)
                  )}
                </td>
                <td className="px-4">{datas?.no_of_items}</td>
                <td className="pt-4 flex">
                  <NavLink
                    to={`/edit-product/${datas?.id}`}
                    className="cursor-pointer pt-3"
                  >
                    <FaEdit />
                  </NavLink>
                  <button
                    type="button"
                    onClick={() => toggleDelete(datas?.id)}
                    className="outline-none font-xs text-red-500 rounded-full text-xs px-2 py-4 text-center"
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
        <div className="rounded-t mb-0 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="w-full px-4 max-w-full p-52 flex-grow flex-1">
              <h3 className="flex justify-center font-bold">
                You haven’t created any Product Link
              </h3>
              <p className="flex text-sm justify-center">
                Click on the button below to create a new
              </p>
              <p className="flex text-sm justify-center text-black font-bold">
                Product.
              </p>

              <NavLink to="/createproduct" className="flex justify-center">
                <span className="flex justify-center pt-4">
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "#0071BC",
                      borderRadius: "50px",
                    }}
                    className="text-white hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-40 px-5 py-2.5 text-center"
                  >
                    + Create New
                  </button>
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <div
      className="p-2 shadow animate-pulse md:p-6 dark:border-gray-700"
      style={{ height: "70vh", width: "78vw" }}
    >
      <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-400"></div>
      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-48 mb-4"></div>
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400"></div>
      <div className="flex items-center mt-4 space-x-3"></div>
      <span className="sr-only">Loading...</span>
    </div>
  )}
</div>

              <div className="m-4 mt-10 flex justify-end">
                {data?.data?.links
                  ?.filter((item, idx) => idx < 1000)
                  .map((datas, index) => (
                    <button
                      onClick={() =>
                        paginator(
                          datas?.label == "Next &raquo;"
                            ? datas?.url.charAt(datas?.url.length - 1)
                            : datas?.label === "&laquo; Previous"
                            ? datas?.url.charAt(datas?.url.length - 1)
                            : datas?.label
                        )
                      }
                      disabled={datas?.active}
                      className={
                        "mx-1 py-1 px-2 " +
                        (datas?.active == false
                          ? "bg-gray-300 text-black "
                          : "bg-[#0071BC] text-white")
                      }
                    >
                      {datas?.label == "&laquo; Previous"
                        ? "< Previous"
                        : datas?.label === "Next &raquo;"
                        ? "Next  >"
                        : datas?.label}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
        </div>
      </div>

      <section>
        <Modal
          visible={visible}
          width="400"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setVisible(false)}
        >
          <div className=" " style={{ height: "100%", overflow: "auto" }}>
            <span className="flex justify-end p-3">
              <p
                className="cursor-pointer font-bold"
                onClick={(e) => setVisible(false)}
              >
                <SvgElement type={icontypesEnum.CANCEL} />
              </p>
            </span>
            <div className=" flex flex-row justify-around bg-[#fff]  items-center rounded-lg p-1">
              <div className="">
                <span className="flex justify-around">
                  {/* <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1> */}
                </span>

                <label className="flex justify-start  mb-2 pt-2 text-md font-bold text-black">
                  Edit User Message
                </label>

                <label className="flex justify-start  mb-2 pt-2 text-xs font-medium text-gray-600">
                  User message
                </label>

                <form onSubmit={handleSubmit} className="pb-4 rounded-lg">
                  <div className="mb-6 ">
                    <textarea
                      id="message"
                      rows={3}
                      defaultValue={value?.link_info?.message}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-gray-500 focus:border-gray-500"
                      placeholder="User message"
                      style={{ backgroundColor: "#F5F5F5" }}
                      onChange={(e) => setMessage(e?.target?.value)}
                    ></textarea>
                  </div>

                  {/* <div className="mb-6 ">
                    <input id="message" type="text" defaultValue={value?.name} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-gray-500 focus:border-gray-500" placeholder="User message" style={{ backgroundColor: '#F5F5F5' }} onChange={(e) => setName(e?.target?.value)} />
                  </div> */}

                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      style={{
                        backgroundColor: "#61A24F",
                        borderRadius: "50px",
                      }}
                      className=" text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Update
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setVisible(false)}
                      style={{ borderRadius: "50px" }}
                      className=" text-black bg-gray-300 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </section>

      <section>
        <Modal
          visible={toggleDeleteModal}
          width="350"
          height="430"
          effect="fadeInUp"
          onClickAway={() => setToggleDeleteModal(false)}
        >
          <div className=" " style={{ height: "100%", overflow: "auto" }}>
            <div className="container flex flex-row justify-around bg-[#fff]  items-center rounded-lg p-2">
              <div className="px-3">
                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-end px-2 pt-3">
                  <p
                    className="cursor-pointer font-bold"
                    onClick={(e) => setToggleDeleteModal(false)}
                  >
                    <SvgElement type={icontypesEnum.CANCEL} />
                  </p>
                </span>

                <label className="flex justify-start mb-2 pt-1 text-md font-bold text-black">
                  Delete Product
                </label>

                <label
                  style={{ fontSize: "14px" }}
                  className="flex justify-start mb-2 pt-2 text-xs font-medium text-gray-600"
                >
                  You are about to delete the Product you created.
                </label>
                <label
                  style={{ fontSize: "14px" }}
                  className="flex justify-start mb-2 pt-2 text-xs font-medium text-gray-600"
                >
                  Please note that:
                </label>

                <ul class="space-y-1 max-w-md list-disc list-inside text-gray-500 dark:text-gray-400 pl-2">
                  <li
                    style={{ color: "#2C2C2C", fontSize: "14px" }}
                    className="text-xs"
                  >
                    All Product data will be lost
                  </li>
                </ul>

                <form onSubmit={deleteLink} className="pb-4 rounded-lg">
                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      style={{ borderRadius: "50px", color: "#F52424" }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Delete Link
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setToggleDeleteModal(false)}
                      style={{ borderRadius: "50px" }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <Oval
                      height={40}
                      width={40}
                      color="#0071BC"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loader}
                      ariaLabel="oval-loading"
                      secondaryColor="#96cff6"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </span>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}


import React, { useState, useMemo } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../reducer/loginSlice'
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { AuthLiveApis } from "../../apis/live/authLiveApis";
import { AuthApis } from "../../apis/authApis";
import { store } from "../../store/store";
import { History } from "history";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../Navbars/Navbar";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import Modal from 'react-awesome-modal';
import { PhoneInput } from "react-contact-number-input";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { FaTrash, FaEdit } from "react-icons/fa";
import { AiOutlineWarning } from "react-icons/ai";
import { convertUTCDateToLocalDate } from "../Reusable/functions";

// components



export default function CardWallet() {
  const navigate = useNavigate();
  const userLoginData = useSelector((state) => state.data.login.value);

  const [userLists, setUserList] = React.useState([]);
  const [walletDetails, setWalletDetails] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');

  let [visible, setVisible] = React.useState(false);
  let [value, setvalue] = React.useState(false);
  const [data, setData] = useState('');

  const [email, setEmail] = useState(data?.email);
  const [phoneNumber, setPhoneNumber] = useState(data?.phone_number);
  const [firstName, setFirstName] = useState(data?.first_name);
  const [lastName, setLastName] = useState(data?.last_name);
  const [address, setAddress] = useState(data?.address);

  function toggleModal() {
    setVisible(!visible)
  }


  React.useEffect(() => {
    if (!userLoginData?.id) {
      navigate('/sign-in');
    }
    AdminApis.getProfile().then(
      (response) => {
        if (response?.data) {
          setData(response?.data?.data)
          setEmail(response?.data?.data?.email)
          setPhoneNumber(response?.data?.data?.phone_number)
          setFirstName(response?.data?.data?.first_name)
          setLastName(response?.data?.data?.last_name)
          setAddress(response?.data?.data?.address)
        }

      }
    )
  }, []);

  let [toggle, setToggle] = React.useState(false);
  function togglePasswordModal() {
    setToggle(!toggle)
  }

  function toggleCloseModal() {
    setVisible(!visible)
    navigate('/mylinks');
  }

  function isCopied() {
    toast.success("Copied to Clipard");
  }

  const [amount, setAmount] = useState('');

  // How to access the redux store





  // This is used to update the store
  const dispatch = useDispatch();


  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData()
      formData.append('amount', amount)
      AdminApis.topUp(formData).then(
        (response) => {
          if (response?.data) {
            window.location.replace(response?.data?.data?.data?.link);
          }

        }
      ).catch(function (error) {
        toast.error("Please, update all fields");
      })
    },
    [amount]
  );


  React.useEffect(() => {
    const query = {
      search: searchText,
    };
    AdminApis.getTransactionHistory('').then(
      (response) => {
        if (response?.data) {
          setUserList(response?.data?.data)
        } else {
          dispatch(login([]))
        }
      }
    ).catch(function (error) {

    });


    AdminApis.getWalletInfo().then(
      (response) => {
        if (response?.data) {
          setWalletDetails(response?.data?.data)
        } else {
          dispatch(login([]))
        }
      }
    )
  }, []);

  const paginator = React.useCallback(
    (value) => {

      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query = {
        search: searchText,
      };

      AdminApis.getTransactionHistory(value2).then(
        (response) => {
          if (response?.data) {
            setUserList(response?.data?.data)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [userLists, searchText]);



  return (
    <>

      <div className="pb-32 md:mt-10 mt-4 mx-4">
        <div className=" w-full border " style={{}}>
          <div className="bg-[#0071BC] text-white p-1 py-3 rounded-sm rounded-t-sm border-gray-500 px-4">
            <span className="flex text-lg font-semibold">Wallet</span>
          </div>

          <div className="flex text-center flex-col bg-[#F2F4F8] m-7">
            <span className="text-[#027DCB] text-[16px] font-[500] py-2">My wallet balance</span>
            <span className=" text-[32px] font-[500] py-2"> {(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((walletDetails?.total_amount) ? (walletDetails?.total_amount) : 0.0))} </span>

            <div className="py-3">
              <button
                type="button"
                onClick={toggleModal}
                style={{ backgroundColor: '#0071BC' }}
                className="text-sm px-10  md:w-1/3 py-2.5 text-center rounded text-white hover:bg-[#0071BC] font-medium"
              >
                Fund Wallet
              </button>
            </div>

        
          </div>
          <div className='py-4 lg:px-10 px-6 '>
              {/* <h1 className='text-[16px] font-semibold'>Transaction History</h1> */}
              {/* <div className='flex justify-end'>
                <div className=" mr-3 flex justify-end">
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                    </div>
                    <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                  </div>

                  <div className='mt-0.5 ml-2'><button type='button' onClick={(e) => paginator('')} className={"font-normal text-white bg-[#0071BC] px-3 py-0.5 rounded-md"}>Search</button> </div>
                </div>

             
              </div> */}




             


                <div className="relative overflow-x-auto">
                  {/* <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                         Amount
                        </th>
                        <th scope="col" className="px-6 py-3"></th>
                        <th scope="col" className="px-6 py-3">Transaction Type</th>

                        <th scope="col" className="px-6 py-3">
                          Transaction Status
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Transaction Date
                        </th>
                      </tr>
                    </thead>


                    <tbody className=''>
                      {
                        userLists?.data?.map(
                          (datas, index) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                              <td className="px-6 py-4 flex justify-start gap-3">
                                <span>{index + 1}</span>
                                <span className="font-[600]">{(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((datas?.amount_paid) ? (datas?.amount_paid) : 0.0))} </span>
                              </td>
                              <td className="px-6 py-4"></td>
                              <td className="px-6 py-4">
                              {datas?.product_qty == 'pay for products' ? <p><b style={{ fontWeight: "400", fontSize: '15px', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>⬇ {datas?.product_qty}</b></p> : ''}
                                {datas?.product_qty == 'topup' ? <p><b style={{ fontWeight: "400", fontSize: '15px',color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}> ⬆ {datas?.product_qty}</b></p> : ''}
                               </td>
                              <td className="px-6 py-4">
                                {datas?.transaction_status == 'pending' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.transaction_status}</b></p> : ''}
                                {datas?.transaction_status == 'successful' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.transaction_status}</b></p> : ''}
                                {datas?.transaction_status == 'delievered' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.transaction_status}</b></p> : ''}
                                {datas?.transaction_status == 'rejected' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#F5BFBF', color: '#E03130', borderRadius: '10px', padding: '2px 10px' }}>{datas?.transaction_status}</b></p> : ''}
                               
                              </td>

                              <td className="px-6 py-4"> {convertUTCDateToLocalDate(datas?.created_at)}</td>
                            </tr>
                          )
                        )
                      }

                    </tbody>


                  </table> */}
                </div>
                <div className="">
  <h1 className='text-[16px] font-semibold pb-2'>Transaction History</h1>
  {userLists?.data?.map((datas, index) => (
    <div className="flex justify-between items-center space-y-5 border-t border-b border-[#E3E4E5] py-4">
      <div className="flex flex-col w-2/3"> {/* Ensures the left content takes up most of the width */}
        <div>
          {datas?.product_qty === 'pay for products' && (
            <p>
              <b
                style={{
                  fontWeight: "400", 
                  fontSize: '20px', 
                  color: '#FF922B', 
                  borderRadius: '10px', 
                  // padding: '0px 10px '
                }}>
                {datas?.product_qty.charAt(0).toUpperCase() + datas?.product_qty.slice(1)}
              </b>
            </p>
          )}
          {datas?.product_qty === 'topup' && (
            <p>
              <b
                style={{
                  fontWeight: "400", 
                  fontSize: '20px', 
                  color: '#51CF66', 
                  borderRadius: '10px', 
                  // padding: '2px 10px'
                }}>
                {datas?.product_qty.charAt(0).toUpperCase() + datas?.product_qty.slice(1)}
              </b>
            </p>
          )}
           {datas?.product_qty !== 'pay for products' && datas?.product_qty !== 'topup' && (
            <p>
              <b
                style={{
                  fontWeight: "400", 
                  fontSize: '20px', 
                  color: '#B0BEC5', 
                  borderRadius: '10px', 
                  // padding: '2px 10px'
                }}>
                Payment Not Defined
              </b>
            </p>
          )}
        </div>
        <div>
          <p className="text-[12px]">
            {convertUTCDateToLocalDate(datas?.created_at)}
          </p>
        </div>
      </div>
      <div className="flex justify-start w-[120px] text-right"> {/* Adjust the width to fit the price consistently */}
        <span className="font-[600] text-green-500">
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(datas?.amount_paid || 0.0)}
        </span>
      </div>
    </div>
  ))}
</div>



              
              <div className='m-4 flex justify-end'>
                {
                  userLists?.links?.filter(((item, idx) => idx < 1000)).map(
                    (datas, index) => (
                      <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#0071BC] text-white')}>
                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                      </button>
                    )
                  )
                }

              </div>

            </div>
        </div>
      </div>



      <section>
        <Modal
          visible={visible}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => setVisible(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>
            <span className="flex justify-end pr-2 pt-2">
              <p className="cursor-pointer font-bold" onClick={(e) => setVisible(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
            </span>
            <div className=" bg-[#fff]  items-center rounded-lg p-1 px-4">

              <div className="">

                <span className=" font-[700] flex justify-center">Fund Wallet</span>

                <form onSubmit={handleSubmit}>
                  <div className="grid  gap-2">
                    <div>
                      <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Amount</label>
                      <input required type="number" onChange={(e)=>setAmount(e.target.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
                    </div>

                   

                  </div>

                  <span className="flex justify-center pt-8">
                    <button
                      type="submit"

                      style={{ backgroundColor: '#0071BC', borderRadius: '50px' }}
                      className=" text-white hover:bg-[#0071BC] focus:ring-4 focus:outline-none focus:ring-[#0071BC] font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Proceed
                    </button>
                  </span>
                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}

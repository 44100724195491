import React from "react";
import { FcGoogle } from "react-icons/fc";
import { AuthApis } from "../../apis/authApis";

const GoogleLoginButton = () => {
    const handleLogin = async () => {
        try {
          const response = await AuthApis.googleLogin(); 
    
          if (response.data.url) {
            window.location.href = response.data.url; 
          } else {
            console.error("Google Auth URL not found");
          }
        } catch (error) {
          console.error("Error fetching Google Auth URL:", error);
        }
      };
    

  return (
    <div className="flex items-center justify-center mt-5">

    <button
      onClick={handleLogin}
      className="flex flex-row bg-white text-black font-normal text-base px-6 py-2 rounded-lg border-2 border-[#0071BC] hover:bg-blue-200 transition shadow-lg"
      >
       <span><FcGoogle width={26} className="mt-[5px] mr-2"/> </span > Login with Google
    </button>
        </div>
  );
};

export default GoogleLoginButton;

import { toast } from "react-toastify";
import { CartApis } from "../../apis/cartApis";
import { setCartData } from "../../store/stateSlice";

const cartKey = "guest_cart"; // Local storage key
export const addToCartCart = (cart, userLoginData, routeLocation, navigate, dispatch) => {
    if (!userLoginData?.id) {
      // Store cart items in localStorage before navigating to login
      const guestCart = JSON.parse(localStorage.getItem(cartKey)) || [];
      const newItem = {
        product_id: cart?.item_id,  // Use cart.item_id
        product_price: cart?.item_price,  // Use cart.item_price
        quantity: 1,
        product_name: cart?.item_name,  // Use cart.item_name
        product_img: cart?.item_img || "no image",  // Use cart.item_img
      };
  
      // Avoid duplicate entries
      const updatedCart = [...guestCart.filter(item => item.product_id !== newItem.product_id), newItem];
      localStorage.setItem(cartKey, JSON.stringify(updatedCart));
  
      navigate("/sign-in");
      return;
    }
  
    // Add the current cart item to the backend cart
    const data = {
      product_id: cart?.item_id,  // Use cart.item_id
      product_price: cart?.item_price,  // Use cart.item_price
      quantity: 1,
      product_name: cart?.item_name,  // Use cart.item_name
      product_img: cart?.item_img || "no image",  // Use cart.item_img
      user_id: userLoginData?.id,
    };
  
    CartApis.createCart(data)
        .then((response) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            window.location.reload();
            fetchCart(dispatch)
          }
        })
        .catch((error) => {
          console.error(error.response?.data);
          toast.error("Offline");
        });
    };
    const fetchCart = async (dispatch) => {
        try {
          const response = await CartApis.getCartItems(); 
          if (response?.data) {
            dispatch(setCartData(response?.data?.cart_count)); 
          }
        } catch (error) {
          console.error("Error fetching cart:", error);
        }
      };
    
      export const addLocalStorageDataToCartOnLogIn = (userLoginData) => {
        const storedCart = JSON.parse(localStorage.getItem(cartKey)) || [];
        if (storedCart.length > 0) {
          storedCart.forEach(async (item) => {
            const data = { ...item, user_id: userLoginData.id };
            await CartApis.createCart(data);
          });
      
          // Clear localStorage after merging
          localStorage.removeItem(cartKey);
        }
      }
    
import React from "react";

// components

import CardCreateCategory from "../Cards/CardCreateCategory";
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";

export default function CreateCategory() {
  return (
    <>
  <Sidebar title="Create Category"/>
      <div className="relative md:ml-36 bg-white">
      <AdminNavbar title="Create Category"/>
      <div className="flex flex-wrap md:mt-4 mt-1">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <CardCreateCategory />
        </div>
      </div>
      </div>
    </>
  );
}

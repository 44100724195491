import { FaStar } from "react-icons/fa";

interface RatingProps {
  activeStars: boolean[];
  productId: number;
  onStarClick: (index: number) => void;
}

const Rating: React.FC<RatingProps> = ({ productId,activeStars, onStarClick }) => {
  return (
    <div className="flex">
      {activeStars.map((isActive, index) => (
        <div key={index} onClick={() => onStarClick(index)} className="px-1">
          {isActive ? (
            <FaStar className="text-yellow-300 h-5 w-5 cursor-pointer" />
          ) : (
            <FaStar className="text-[#0071BC] h-5 w-5 cursor-pointer" />
          )}
        </div>
      ))}
    </div>
  );
};

export default Rating;

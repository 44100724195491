import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

export default function BestSellers() {
  return (
    <div className='shadow-xl bg-white rounded-xl p-5'>
<div className="flex flrx-row justify-between">
  <h1 className='font-semibold text-lg text-[#1c2a53]'>Best Sellers</h1>
  <h5 className='flex flex-row text-base font-medium'>More <span> <FaArrowRight width={24} className='mt-1 ml-2'/> </span> </h5>
</div>
<div className="mt-5">
    <table className='w-full text-sm text-left rtl:text-right text-gray-500'>
        <thead className='text-xs text-gray-700 bg-gray-50'>
        <tr className=''>
        <th scope="col" className="px-6 py-3">Produc</th>
        <th scope="col" className="px-6 py-3">Price</th>
        <th scope="col" className="px-6 py-3">Sold</th>
        <th scope="col" className="px-6 py-3">Profit</th>
        </tr>
        </thead>

        <tbody>
        <tr>
            <td className='px-6 py-3'>Alpenliebe</td>
            <td className='px-6 py-3'>N50</td>
            <td className='px-6 py-3'>30</td>
            <td className='px-6 py-3'>N9,000</td>
        </tr>
        <tr>
            <td className='px-6 py-3'>Huggies</td>
            <td className='px-6 py-3'>N9000</td>
            <td className='px-6 py-3'>15</td>
            <td className='px-6 py-3'>N50,000</td>
        </tr>
        <tr>
            <td className='px-6 py-3'>Chin Chin</td>
            <td className='px-6 py-3'>N1750</td>
            <td className='px-6 py-3'>3</td>
            <td className='px-6 py-3'>N27,000</td>
        </tr>
        <tr>
            <td className='px-6 py-3'>Soda Crackers</td>
            <td className='px-6 py-3'>N250</td>
            <td className='px-6 py-3'>3</td>
            <td className='px-6 py-3'>N5,000</td>
        </tr>
        <tr>
            <td className='px-6 py-3'>Choco Rings</td>
            <td className='px-6 py-3'>N20</td>
            <td className='px-6 py-3'>13</td>
            <td className='px-6 py-3'>N25,000</td>
        </tr>
        </tbody>

    </table>
</div>
    </div>
  )
}

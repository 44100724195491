import React, { useEffect } from "react";
import { useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../reducer/gooogleLoginSlice";
import { AuthApis } from "../../apis/authApis";
import { useSearchParams } from "react-router-dom";

const GoogleCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let [searchParams] = useSearchParams();

  console.log(searchParams.get('code'), 'params');

  const code = searchParams.get('code');

  useEffect(() => {
    const handleGoogleAuth = async () => {
      if (!code) {
        console.error("No authorization code found");
        // navigate("/login");
        return ;
        
      }
      <div>Loading...</div>; 

      try {
        const response = await AuthApis.verifyGoogleLogin(code)
        const data = await response.data;

        console.log(response, 'response');
        if (data.status && data.token) {
          localStorage.setItem("authToken", data.token);
          dispatch(setUser(data.data));
          // Redirect to the homepage
          navigate("/");
        } else {
          console.error("Authentication failed:", data.message);
          // navigate("/login"); 
        }
      } catch (error) {
        console.error("Error verifying Google login:", error);
        // navigate("/"); 
      }
    };

    handleGoogleAuth();
  }, [navigate, dispatch]);

  return <div>Loading...</div>; 
};

export default GoogleCallback;
import React from 'react'
import { Routes, Route } from "react-router-dom";
import Home from './Components/Home';
import Deal from './Components/Deals';
import Category from './Components/Category';
import ViewSingleProduct from "./Components/admin/ViewSingleProduct";
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import VerifyEmail from './Components/Auth/VerifyEmail';
import EmailVerifyComplete from './Components/Auth/EmailVerifyComplete'
import ForgotPword from './Components/Auth/ForgotPword';
import ResetPassword from './Components/Auth/ResetPassword';
import OTPVerify from './Components/Auth/OTPVerify';
import MiniStore from './Components/admin/MiniStores';
import Dashboard from './Components/admin/Dashboard';
import CustomerList from './Components/admin/CustomerList';
import CreateProduct from './Components/admin/CreateProduct';
import CreateCategory from './Components/admin/CreateCategory';
import EditProduct from './Components/admin/EditProduct';
import Deals from './Components/admin/Deals';
import EditCategory from './Components/admin/EditCategory';
import ViewProductDetailsPage from './Components/user/ViewProductDetailsPage';
import CartPage from './Components/user/CartPage';
import Feedback from './Components/Feedback';
import RefundPolicy from './Components/RefundPolicy';
import PurchaseList from './Components/admin/PurchaseList';
import Categories from './Components/admin/Categories';
import PurchaseLists from './Components/user/PurchaseList';
import Wishlist from './Components/user/Wishlist';
import EditProfile from './Components/user/EditProfile';
import Wallet from './Components/user/Wallet';
import VerifyPayment from './Components/user/verifyPayment';
import VerifyWalletPayment from './Components/user/verifyWalletPayment';
import PwordResetSucces from './Components/Auth/PwordResetSucces';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsAndCondition from './Components/TermsAndCondition';
import Stores from './Components/admin/Stores';
import CreateStore from './Components/admin/CreateStore';
import EditStore from './Components/admin/EditStore';
import Promotions from './Components/admin/promotions/Promotions';
import CreatePromotions from './Components/admin/promotions/CreatePromotions';
import EditPromotion from './Components/admin/EditPromotion';
import CreateDeals from './Components/admin/CreateDeals';
import EditDeal from './Components/admin/EditDeal';
import OrderPurchaseLists from './Components/user/OrderPurchaseLists';
import PayWithTransfer from './Components/user/PayWithTransfer';
import VerifyPaymentTwo from './Components/user/VerifyPaymentTwo';
import GoogleCallback from './Components/Auth/GoogleCallback';
import PendingReview from './Components/user/PendingReview';
import UsersInbox from './Components/user/UserInbox';
import Voucher from './Components/user/Vouchers';
import RecentlyViewed from './Components/user/RecentlyViewed';


function App() {
  return (
    <div>
    
      {/* <Navbar/> */}
      
      <Routes>
      {/* <Route  path="*" element={<Home/>}/>   */}
           <Route  path="/" element={<Category/>}/>  
           <Route  path="/product" element={<Home/>}/>  
           <Route  path="/deal" element={<Deal/>}/>  
           <Route  path="/sign-in" element={<Login/>}/>
           <Route  path="/sign-up" element={<Register/>}/>
           <Route  path="/auth/google/callback" element={<GoogleCallback />} />
           <Route  path="/verify-email" element={<VerifyEmail/>}/>
           <Route path="/email-verify/:email" element={<EmailVerifyComplete />} />
           <Route  path="/forgot-password" element={<ForgotPword/>}/>
           <Route  path="/otp-verify" element={<OTPVerify/>}/>
           <Route  path="/reset-password" element={<ResetPassword/>}/>
           <Route  path="/view-product/:id" element={<ViewProductDetailsPage/>}/>
           <Route  path="/view-cart" element={<CartPage/>}/>
           <Route  path="/view-cart/pay-with-transfer" element={<PayWithTransfer/>}/>
           <Route  path="/feedback" element={<Feedback/>}/>
           <Route  path="/refund-policy" element={<RefundPolicy/>}/>
           <Route  path="/privacy-policy" element={<PrivacyPolicy/>}/>
           <Route  path="/terms-and-condition" element={<TermsAndCondition/>}/>

           <Route  path="/user/view-purchase" element={<PurchaseLists/>}/>
           <Route  path="/user/pending-review" element={<PendingReview/>}/>
           <Route  path="/user/users-inbox" element={<UsersInbox/>}/>
           <Route  path="/user/voucher" element={<Voucher/>}/>
           <Route  path="/user/recently-viewed" element={<RecentlyViewed/>}/>
           <Route  path="/user/view-purchase/order/:id" element={<OrderPurchaseLists/>}/>
           <Route  path="/user/wishlist" element={<Wishlist/>}/>
           <Route  path="/user/profile" element={<EditProfile/>}/>
           <Route  path="/user/wallet" element={<Wallet/>}/>
           
           <Route  path="/admin/view-purchase" element={<PurchaseList/>}/>
           <Route  path="/admin/categories" element={<Categories/>}/>
           <Route path="/admin/create-category" element={<CreateCategory />} />
           <Route path="/edit-category/:id" element={<EditCategory />} />
           <Route  path="/verify-payment" element={<VerifyPayment/>}/>
           <Route  path="/verify-payment-details" element={<VerifyPaymentTwo/>}/>
           <Route  path="/verify-wallet-payment" element={<VerifyWalletPayment/>}/>
           <Route  path="/reset-success" element={<PwordResetSucces/>}/>
           {/* <Route path="/createproduct" element={<CreateProduct />} /> */}
           <Route path="/createproduct" element={<CreateProduct />} />

           
           <Route path="/edit-product/:id" element={<EditProduct />} />
           <Route path="/admin/deals" element={<Deals />} />
           <Route path="/admin/create-deals" element={<CreateDeals />} />
           <Route path="/edit-deal/:id" element={<EditDeal />} />
           <Route path="/admin/dashboard" element={<Dashboard />} />
           <Route path="/admin/customers" element={<CustomerList />} />
           <Route path="/admin/products" element={<MiniStore />} />

           <Route  path="/admin/stores" element={<Stores/>}/>
           <Route path="/admin/create-store" element={<CreateStore />} />
           <Route path="/edit-store/:id" element={<EditStore />} />

           <Route  path="/admin/promotions" element={<Promotions/>}/>
           <Route path="/admin/create-promotions" element={<CreatePromotions />} />
           <Route path="/edit-promotion/:id" element={<EditPromotion />} />

      </Routes>
    </div>
  );
}

export default App;

import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

export default function SalesRevenue() {
  return (
    <div className='shadow-xl bg-white rounded-xl p-5'>
<div className="flex flex-row justify-between">
  <h1 className='font-semibold text-lg text-[#1c2a53]'>Sales forecast</h1>
  <h5 className='flex flex-row text-base font-medium'>More <span> <FaArrowRight width={24} className='mt-1 ml-2'/> </span> </h5>
</div>
<div className='grid lg:grid-cols-2 grid-cols-2 gap-6 mt-5'>
          <div className=''>
            <div className='bg-white rounded-[10px] px-[15px] pt-[20px] pb-[24px] border-[1px] border-[#dcdada] shadow-lg'>
              <h5 className='text-[12px]'>Revenue</h5>
              <div className='flex justify-between gap-4'>
                <h1 className='text-[24px] font-semibold'>24.2%</h1>
                <div className='flex space-x-1 mt-2 '>
                  <div className='mt-1'>
                    <svg width="85" height="41" viewBox="0 0 85 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_57_1136)</g>">
                        <path d="M5 24.3152C7.01238 25.1501 12.3839 25.0894 17.7709 18.1671C24.5046 9.51427 30.774 11.7913 38.2043 21.5827C45.6347 31.3741 50.743 36.156 58.4056 22.7213C66.0681 9.2866 66.9969 4.73248 80 2" stroke="#0071DC" stroke-width="3" />
                      </g>
                      <defs>
                        <filter id="filter0_d_57_1136" x="0.425171" y="0.532104" width="83.8833" height="39.9683" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0 0.941176 0 0 0 0.16 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_57_1136" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_57_1136" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className=''>
            <div className='bg-white rounded-[10px] px-[15px] pt-[20px] pb-[24px] border-[1px] border-[#dcdada] shadow-lg'>
              <h5 className='text-[12px]'>Net Profit</h5>
              <div className='flex justify-between gap-4'>
                <h1 className='text-[24px] font-semibold'>-2.5%</h1>
                <div className='flex space-x-1 mt-2 '>
                  <div className='mt-1'>
                    <svg width="85" height="41" viewBox="0 0 85 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_57_1136)">
                        <path d="M5 24.3152C7.01238 25.1501 12.3839 25.0894 17.7709 18.1671C24.5046 9.51427 30.774 11.7913 38.2043 21.5827C45.6347 31.3741 50.743 36.156 58.4056 22.7213C66.0681 9.2866 66.9969 4.73248 80 2" stroke="#279F51" stroke-width="3" />
                      </g>
                      <defs>
                        <filter id="filter0_d_57_1136" x="0.425171" y="0.532104" width="83.8833" height="39.9683" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0 0.941176 0 0 0 0.16 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_57_1136" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_57_1136" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div className=''>
            <div className='bg-white rounded-[10px] px-[15px] pt-[20px] pb-[24px] border-[1px] border-[#dcdada] shadow-lg'>
              <span className="flex justify-between">
                <h5 className='text-[12px]'>Orders </h5>

              </span>

              <div className='flex justify-between gap-4'>
              <h1 className='text-[24px] font-semibold'>-2.5%</h1>
                <div className='flex space-x-1 mt-2 '>
                  <div className='mt-1'>
                    <svg width="85" height="41" viewBox="0 0 85 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_57_1136)">
                        <path d="M5 24.3152C7.01238 25.1501 12.3839 25.0894 17.7709 18.1671C24.5046 9.51427 30.774 11.7913 38.2043 21.5827C45.6347 31.3741 50.743 36.156 58.4056 22.7213C66.0681 9.2866 66.9969 4.73248 80 2" stroke="#FFA000" stroke-width="3" />
                      </g>
                      <defs>
                        <filter id="filter0_d_57_1136" x="0.425171" y="0.532104" width="83.8833" height="39.9683" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0 0.941176 0 0 0 0.16 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_57_1136" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_57_1136" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div className=''>
            <div className='bg-white rounded-[10px] px-[15px] pt-[20px] pb-[24px] border-[1px] border-[#dcdada] shadow-lg'>
              <h5 className='text-[12px]'>Visitors</h5>
              <div className='flex justify-between gap-4'>
                <h1 className='text-[24px] font-semibold'>+24.2%</h1>
                <div className='flex space-x-1 mt-2 '>
                  <div className='mt-1'>
                    <svg width="85" height="41" viewBox="0 0 85 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_57_1136)">
                        <path d="M5 24.3152C7.01238 25.1501 12.3839 25.0894 17.7709 18.1671C24.5046 9.51427 30.774 11.7913 38.2043 21.5827C45.6347 31.3741 50.743 36.156 58.4056 22.7213C66.0681 9.2866 66.9969 4.73248 80 2" stroke="#FF392B" stroke-width="3" />
                      </g>
                      <defs>
                        <filter id="filter0_d_57_1136" x="0.425171" y="0.532104" width="83.8833" height="39.9683" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0 0.941176 0 0 0 0.16 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_57_1136" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_57_1136" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
    </div>

  )
}

import React, { useEffect } from "react";

// components

// import CardUserPurchaseList from "../Cards/CardUserPurchaseList";
// import AdminNavbar from "../Navbars/AdminNavbar.js";
// import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbars/Navbar";
import UserSidebar from "../Sidebar/UserSidebar";
import CardInbox from "../Cards/CardInbox";


export default function UsersInbox() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="flex relative  bg-white">
        <div className="hidden md:flex md:w-1/5">
          <UserSidebar title="Purchase List" />
        </div>

        <div className="md:w-4/5 w-full md:px-10 ">
          <CardInbox />
        </div>
      </div>
    </>
  );
}

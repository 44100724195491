import React, { useCallback, useEffect, useRef, useState } from "react";
import Categories from "./Categories";
import MostViewed from "./Groups/MostViewed";
import { ProductApis } from "../apis/productApis";
import { AxiosResponse } from "axios";
import { SvgElement, icontypesEnum } from "./assets/svgElement";
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
  Link,
} from "react-router-dom";
import { FaEye, FaWhatsapp, FaRegHeart, FaHeart, FaArrowRight, FaArrowLeft, FaArrowAltCircleLeft, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import configs from "../configs";
import Modal from "react-responsive-modal";
import { PaymentApis } from "../apis/paymentApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { login } from "../reducer/loginSlice";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//@ts-ignore
import Slider from "react-slick";

//@ts-ignore
import { AuthApis } from "../apis/authApis";
import { CartApis } from "../apis/cartApis";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { hideAll } from "tippy.js";
import UserSidebar from "./Sidebar/UserSidebar";
import Navbar from "./Navbars/AdminNavbar";
import Sidebar from "./Sidebar/Sidebar";
import { IoArrowBack } from "react-icons/io5";
import { AiOutlineSetting } from "react-icons/ai";
import { CategoryApis } from "../apis/CategoryApis";
//@ts-ignore
import Carousel from "react-grid-carousel";
import Footer from "./Footers/Footer";
import AutoCompleteSearch from "./Navbars/AutoCompleteSearch";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Custom Arrow Components
const CustomPrevArrow = ({ onClick }: any) => (
  <div
    className="absolute left-[-10px] top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
    onClick={onClick}
  >
     {/* <FaArrowLeft /> */}
     {/* <FaChevronLeft className=" w-8 h-8"/> */}
    <FaChevronLeft className="text-blue-900  w-8 h-8" />
  </div>
);

const CustomNextArrow = ({ onClick }: any) => (
  <div
    className="absolute right-[-10px] top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
    onClick={onClick}
  >
   <FaChevronRight className="text-blue-900  w-8 h-8" />
  </div>
);

interface SearchResult {
  id: number;
  name: string;
  description?: string;
  type: "product" | "category";
  highlight: string;
}

function Home() {
  const userLoginData = useSelector((state: any) => state.data.login.value);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const routeLocation = useLocation();

  useEffect(() => {
    const carousel: any = carouselRef.current;
    let scrollAmount = 0;

    const scroll = () => {
      if (carousel?.scrollWidth - carousel?.clientWidth === scrollAmount) {
        carousel?.scrollTo({ left: 0, behavior: "smooth" });
        scrollAmount = 0;
      } else {
        carousel?.scrollBy({ left: 1, behavior: "smooth" });
        scrollAmount += 1;
      }
    };

    const interval = setInterval(scroll, 10);

    return () => clearInterval(interval);
  }, []);

  const carouselRef = useRef(null);

  const dispatch: Dispatch = useDispatch();

  const [products, setProducts] = React.useState<any>([]);
  const [loc, setLoc] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [category, setCategory] = React.useState(
    routeLocation?.state?.datas?.cat_name
  );
  const [fetchCategory, setFetchCategory] = React.useState("");
  const [searchResult, setSearchresult] = React.useState("");
  const [loader, setLoader] = React.useState<boolean>(false);
  let [fullName, setFullName] = React.useState("");
  let [email, setEmail] = React.useState("");
  const [location, setLocation] = React.useState("");
  let [phoneNumber, setPhoneNumber] = React.useState<any>("");
  let [productQty, setProductQty] = React.useState<any>("");
  const [collapseShow, setCollapseShow] = React.useState("hidden");

  let [target, setTarget] = React.useState<Element>();

  let [visible, setVisible] = React.useState(false);
  let [value, setvalue] = React.useState<any>("");

  function togglePaymentModal(value2: any) {
    setvalue(value2);
    setVisible(true);
  }

  const params = useParams();

  const normalizeString = (string: any) => {
    // Normalize the category string by removing spaces and special characters
    return string.replace(/\s+/g, "").replace(/&/g, "");
  };

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [cate, setCate] = React.useState<any>("");
  React.useEffect(() => {
    CategoryApis.getCategory("")
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setCate(response?.data?.data);
        } else {
          dispatch(login([]));
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log(error.response.data);
      });
  }, []);
  // console.log(products)
  const [name, setname] = React.useState("");
  const [total, setTotal] = React.useState<any>("");

  React.useEffect(() => {
    CartApis.getCartCount()
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setname(response?.data?.cart_count);
          setTotal(response?.data?.total);
        } else {
          dispatch(login([]));
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log(error.response.data);
      });
  }, []);

  if (searchParams.get("status") == "cancelled") {
    navigate(`/store/${params?.storeId}`);
  }

  const [userWishLists, setUserWishLists] = React.useState(new Set());

  React.useEffect(() => {
    CartApis.getAllList("")
      .then((response) => {
        if (response?.data) {
          const wishListIds = new Set(
            response?.data?.data.map((item: any) => item.item_id)
          );
          setUserWishLists(wishListIds);
        } else {
          dispatch(login([]));
        }
      })
      .catch(function (error) {
        console.error("Error fetching wishlist:", error);
      });
  }, []);

  const toggleWishlist = React.useCallback(
    (productInfo: any) => {
      if (!userLoginData?.id) {
        navigate("/sign-in");
        return;
      }

      const productId = productInfo?.id;
      const isWished = userWishLists.has(productId.toString());

      if (isWished) {
        // Remove from wishlist
        CartApis.deleteWishlist(productId)
          .then((response) => {
            if (response?.data) {
              setUserWishLists((prev) => {
                const updatedSet = new Set(prev);
                updatedSet.delete(productId.toString());
                return updatedSet;
              });
              toast.success("Removed from wishlist.");
            }
          })
          .catch(function (error) {
            console.error("Error removing from wishlist:", error);
            toast.error("Error removing from wishlist.");
          });
      } else {
        // Add to wishlist
        let data = {
          product_id: productId,
          price: productInfo?.product_price,
          product_name: productInfo?.product_name,
          category: productInfo?.category,
          product_img: productInfo?.product_image_1
            ? productInfo?.product_image_1
            : "no image",
          user_id: userLoginData?.id,
        };
        CartApis.createWishlist(data)
          .then((response) => {
            if (response?.data) {
              setUserWishLists((prev) =>
                new Set(prev).add(productId.toString())
              );
              toast.success("Added to wishlist.");
            }
          })
          .catch(function (error) {
            console.error("Error adding to wishlist:", error);
            toast.error("Error adding to wishlist.");
          });
      }
    },
    [userLoginData, userWishLists, navigate]
  );

  React.useEffect(() => {
    setLoader(true);
    setSearchresult(category);
    setSearch("");
    let query: any = [];
    if (routeLocation?.state?.searchMe) {
      setSearchresult(routeLocation?.state?.searchMe);
      setSearch(routeLocation?.state?.searchMe);
      query = {
        search: routeLocation?.state?.searchMe,
        name: loc,
        categories: "",
      };
    } else {
      const encodedCategory = normalizeString(category);

      query = {
        search: "",
        name: loc,
        categories: encodedCategory,
      };
    }

    ProductApis.getAllProducts("", query)
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setProducts(response.data);
          // console.log(response.data)
          setLoader(false);

          // console.log(response?.data?.data);
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log(error.response.data);
      });
  }, []);

  const getCategory = (cat: any) => {
    setLoader(true);
    setSearchresult(cat);
    setSearch("");

    // Apply URL encoding
    const encodedCategory = normalizeString(cat);

    let query: any = {
      search: "",
      name: "",
      categories: encodedCategory,
    };

    ProductApis.getAllProducts("", query)
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setProducts(response.data);
          setLoader(false);

          // console.log(response?.data?.data);
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log(error.response.data);
      });
  };

  const logOut = () => {
    dispatch(login([]));
    AuthApis.logout("")
      .then((response: AxiosResponse<any>) => {
        if (response) {
          navigate("/");
        }
      })
      .catch(function (error: any) {
        // handle error
        // console.log(error.response.data);
        console.log("new error");
      });
  };

  const searchFilter = React.useCallback(
    (value: any, e: any) => {
      e.preventDefault();

      setLoader(true);
      setSearchresult(search);
      setCategory("");
      let value2 = "";
      if (value !== null) {
        value2 = value;
      } else {
        value2 = "";
      }
      const query: any = {
        search: search,
        name: "",
        categories: "",
      };

      ProductApis.getAllProducts(value2, query)
        .then((response: AxiosResponse<any>) => {
          if (response?.data) {
            setProducts(response.data);
            setLoader(false);
            setFetchCategory(search !== "" ? search : category);
          }
        })
        .catch(function (error: any) {
          console.log("new error");
        });
    },
    [search, searchResult, loc, fetchCategory]
  );

  const paginator = React.useCallback(
    (value: any) => {
      // e.preventDefault();

      // if (search.trim() === '') {
      //   return;
      // }

      setLoader(true);
      setSearchresult(search);
      let value2 = "";
      if (value !== null) {
        value2 = value;
      } else {
        value2 = "";
      }
      const encodedCategory = normalizeString(category);

      const query: any = {
        search: search,
        name: "",
        categories: encodedCategory,
      };
      ProductApis.getAllProducts(value2, query)
        .then((response: AxiosResponse<any>) => {
          if (response?.data) {
            setProducts(response.data);
            setLoader(false);
            // console.log(response.data)
            setFetchCategory(search !== "" ? search : category);
          }
        })
        .catch(function (error: any) {
          console.log("new error");
        });
    },
    [search, category, searchResult, loc, fetchCategory]
  );

  // Store selected category in local storage when it changes
  React.useEffect(() => {
    localStorage.setItem("selectedCategory", category);
  }, [category]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  // var settings2 = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 8,
  //   slidesToScroll: 7,
  // };
  const settings2 = {
    dots: false, // If you want dots navigation, set this to true
    infinite: true,
    speed: 500,
    slidesToShow: 8, // Adjust this based on the number of slides visible
    slidesToScroll: 7,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const [suggestions, setSuggestions] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleFetchSuggestions = useCallback(
    async (searchTerm: string) => {
      if (!searchTerm) {
        setSuggestions([]);
        return;
      }

      setLoader(true);
      setError(null);
      setSearchresult(search);
      setCategory("");
      let value2 = "";
      if (value !== null) {
        value2 = value;
      } else {
        value2 = "";
      }
      const query: any = {
        search: search,
        name: "",
        categories: "",
      };

      try {
        const response = await ProductApis.fetchSuggestions(searchTerm);
        setSuggestions(response.data.results);
        // console.log(response)
        // navigate('/product', { state: { searchMe: query } });
        ProductApis.getAllProducts(value2, query)
          .then((response: AxiosResponse<any>) => {
            if (response?.data) {
              setProducts(response.data);
              setLoader(false);
              setFetchCategory(search !== "" ? search : category);
            }
          })
          .catch(function (error: any) {
            console.log("new error");
          });
      } catch (err) {
        setError("");
        // navigate('/product', { state: { searchMe: query } });
      } finally {
        setLoader(false);
      }
    },
    [search]
  );

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      handleFetchSuggestions(search);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [search, handleFetchSuggestions]);

  return (
    <div className=" overflow-x-hidden">
      <nav className="bg-[#0071BC] fixed w-full hidden z-20 md:block">
        <div className="flex justify-between py-4 gap-4 px-5 mx-32">
          <NavLink to={"/"}>
            <img
              src="./images/white-logo.png"
              width={"100px"}
              className=""
              alt="mart Logo"
            />
          </NavLink>

          {/* <div className='flex mt-1'>
            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.024 1.984H5.024V5.984H1.024V1.984ZM0.512 0.991995C0.362667 0.991995 0.24 1.04 0.144 1.13599C0.048 1.23199 0 1.35466 0 1.50399V6.496C0 6.64533 0.048 6.76799 0.144 6.864C0.24 6.96 0.362667 7.008 0.512 7.008H5.504C5.65333 7.008 5.776 6.96 5.872 6.864C5.968 6.76799 6.016 6.64533 6.016 6.496V1.50399C6.016 1.35466 5.968 1.23199 5.872 1.13599C5.776 1.04 5.65333 0.991995 5.504 0.991995H0.512ZM9.024 1.984V5.984H13.024V1.984H9.024ZM8.512 0.991995H13.504C13.6533 0.991995 13.776 1.04 13.872 1.13599C13.968 1.23199 14.016 1.35466 14.016 1.50399V6.496C14.016 6.64533 13.968 6.76799 13.872 6.864C13.776 6.96 13.6533 7.008 13.504 7.008H8.512C8.36267 7.008 8.24 6.96 8.144 6.864C8.048 6.76799 8 6.64533 8 6.496V1.50399C8 1.35466 8.048 1.23199 8.144 1.13599C8.24 1.04 8.36267 0.991995 8.512 0.991995ZM1.024 9.984V13.984H5.024V9.984H1.024ZM0.512 8.992H5.504C5.65333 8.992 5.776 9.04 5.872 9.136C5.968 9.232 6.016 9.35466 6.016 9.504V14.496C6.016 14.6453 5.968 14.768 5.872 14.864C5.776 14.96 5.65333 15.008 5.504 15.008H0.512C0.362667 15.008 0.24 14.96 0.144 14.864C0.048 14.768 0 14.6453 0 14.496V9.504C0 9.35466 0.048 9.232 0.144 9.136C0.24 9.04 0.362667 8.992 0.512 8.992ZM9.024 9.984V13.984H13.024V9.984H9.024ZM8.512 8.992H13.504C13.6533 8.992 13.776 9.04 13.872 9.136C13.968 9.232 14.016 9.35466 14.016 9.504V14.496C14.016 14.6453 13.968 14.768 13.872 14.864C13.776 14.96 13.6533 15.008 13.504 15.008H8.512C8.36267 15.008 8.24 14.96 8.144 14.864C8.048 14.768 8 14.6453 8 14.496V9.504C8 9.35466 8.048 9.232 8.144 9.136C8.24 9.04 8.36267 8.992 8.512 8.992Z" fill="#F2B705" />
            </svg>

            <h3 className='text-[15px]'>Categories</h3>
          </div> */}

          <div>
            {/* <div className="flex justify-start">
              <label htmlFor="search-dropdown" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>

              <div className="relative w-auto md:w-[200px] lg:w-[500px]">
                <form onSubmit={(e: any) => searchFilter('', e)}>
                  <input type="text" defaultValue={search} onChange={e => setSearch(e.target.value)} id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg  border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Type Keyword here..." />
                  <button type="submit" className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white rounded-r-lg border border-blue-200" style={{ backgroundColor: '#2196F3' }}>
                    <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    <span className="sr-only">Search</span>
                  </button>
                </form>
              </div>
            </div> */}
            <div className="relative w-auto md:w-[200px] lg:w-[500px]">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search products or categories..."
                className="w-full p-3 border border-gray-300 rounded-full shadow-sm bg-white text-[#0071BC] focus:outline-none focus:border-blue-500 placeholder:text-gray-500"
              />
              {loader && (
                <div className="absolute right-3 top-3">
                  <svg
                    className="animate-spin h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.959 7.959 0 014 12H2c0 2.386.832 4.57 2.207 6.293l1.793-1.002z"
                    ></path>
                  </svg>
                </div>
              )}
              {error && <p className="text-red-500 mt-2">{error}</p>}
              {suggestions.length > 0 && (
                <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                  {suggestions.map((suggestion) => {
                    // Remove "- null" if it appears at the end of suggestion.highlight
                    return (
                      <NavLink
                        to={`/view-product/${suggestion.id}`}
                        key={suggestion.id}
                      >
                        <div
                          className="p-3 hover:bg-gray-100 cursor-pointer"
                        >{suggestion?.name}</div>
                      </NavLink>
                    );
                  })}
                </div>
              )}
              {!loader && !error && search && suggestions.length === 0 && (
                <p className="text-white mt-2">No results found</p>
              )}
            </div>
          </div>

          <div className="flex flex-row font-medium mt-0  space-x-5 text-sm">
            <div className="flex space-x-2">
              <div className="mt-3">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 15.3258C1 14.2649 1.42143 13.2475 2.17157 12.4974C2.92172 11.7472 3.93913 11.3258 5 11.3258H13C14.0609 11.3258 15.0783 11.7472 15.8284 12.4974C16.5786 13.2475 17 14.2649 17 15.3258C17 15.8562 16.7893 16.3649 16.4142 16.74C16.0391 17.1151 15.5304 17.3258 15 17.3258H3C2.46957 17.3258 1.96086 17.1151 1.58579 16.74C1.21071 16.3649 1 15.8562 1 15.3258Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 7.32581C10.6569 7.32581 12 5.98266 12 4.32581C12 2.66895 10.6569 1.32581 9 1.32581C7.34315 1.32581 6 2.66895 6 4.32581C6 5.98266 7.34315 7.32581 9 7.32581Z"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>

              {userLoginData?.token ? (
                <Tippy
                  hideOnClick={true}
                  trigger="click"
                  theme="light"
                  interactive={true}
                  arrow={false}
                  placement="bottom-end"
                  offset={[0, -10]}
                  triggerTarget={target}
                  content={
                    <div>
                      <ul>
                        <li className="py-2 cursor-pointer hover:bg-gray-200 px-2">
                          <NavLink to={"/user/profile"}>Profile</NavLink>{" "}
                        </li>
                        <hr />
                        <li className="py-2 cursor-pointer hover:bg-gray-200 px-2">
                          <NavLink to={"/user/view-purchase"}>Orders</NavLink>
                        </li>
                        {/* <hr />
                      <li className='py-2 cursor-pointer hover:bg-gray-200 px-2'><NavLink to={'/user/wallet'}>Wallet</NavLink></li> */}
                        <hr />
                        <li className="py-2 cursor-pointer hover:bg-gray-200 px-2">
                          <NavLink to={"/user/wishlist"}>Wishlist</NavLink>
                        </li>
                        <hr />
                        <li
                          onClick={logOut}
                          className="py-2 cursor-pointer hover:bg-gray-200 px-2"
                        >
                          Logout
                        </li>
                      </ul>
                    </div>
                  }
                >
                  <h3 className="text-[14px] font-normal mt-[10px] cursor-pointer text-white">
                    Hi, {userLoginData?.data?.first_name}
                  </h3>
                </Tippy>
              ) : (
                <NavLink to={"/sign-in"}>
                  {/* <h3 className="text-[11.6px] text-white font-normal">
                    Sign in
                  </h3> */}
                  <h3 className="text-[14px] text-white font-semibold mt-3 mr-5">
                    Account
                  </h3>
                </NavLink>
              )}
                                          <div className="flex mt-2 gap-2 ">
                            <svg width="22" height="22" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2.32581C6.48 2.32581 2 6.80581 2 12.3258C2 17.8458 6.48 22.3258 12 22.3258C17.52 22.3258 22 17.8458 22 12.3258C22 6.80581 17.52 2.32581 12 2.32581ZM13 19.3258H11V17.3258H13V19.3258ZM15.07 11.5758L14.17 12.4958C13.45 13.2258 13 13.8258 13 15.3258H11V14.8258C11 13.7258 11.45 12.7258 12.17 11.9958L13.41 10.7358C13.78 10.3758 14 9.87581 14 9.32581C14 8.22581 13.1 7.32581 12 7.32581C10.9 7.32581 10 8.22581 10 9.32581H8C8 7.11581 9.79 5.32581 12 5.32581C14.21 5.32581 16 7.11581 16 9.32581C16 10.2058 15.64 11.0058 15.07 11.5758Z" fill="#FFC220"/>
</svg>
                                <h3 className='text-white font-normal text-[14px]'>Help</h3>
                            </div>
            </div>

            <NavLink to={"/view-cart"} className="pr-5 flex mt-2">
              <div className="relative flex justify-end ">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_555_604)">
                    <path
                      d="M16.494 17.2218C17.038 17.2218 17.486 17.3978 17.838 17.7498C18.19 18.1018 18.366 18.5418 18.366 19.0698C18.366 19.5978 18.19 20.0458 17.838 20.4138C17.486 20.7818 17.046 20.9658 16.518 20.9658C15.99 20.9658 15.542 20.7818 15.174 20.4138C14.806 20.0458 14.622 19.5978 14.622 19.0698C14.622 18.5418 14.806 18.1018 15.174 17.7498C15.542 17.3978 15.982 17.2218 16.494 17.2218ZM7.51801 17.2218C8.03001 17.2218 8.47001 17.3978 8.83801 17.7498C9.20601 18.1018 9.39001 18.5418 9.39001 19.0698C9.39001 19.5978 9.20601 20.0458 8.83801 20.4138C8.47001 20.7818 8.03001 20.9658 7.51801 20.9658C7.00601 20.9658 6.56601 20.7818 6.19801 20.4138C5.83001 20.0458 5.64601 19.5978 5.64601 19.0698C5.64601 18.5418 5.83001 18.1018 6.19801 17.7498C6.56601 17.3978 7.00601 17.2218 7.51801 17.2218ZM4.25401 2.96582C4.44601 2.96582 4.61401 3.02182 4.75801 3.13382C4.90201 3.24582 4.99001 3.39782 5.02201 3.58982V5.94182H20.766C20.958 5.94182 21.118 5.99782 21.246 6.10982C21.374 6.22182 21.454 6.37382 21.486 6.56582L21.534 15.6858C21.534 15.8778 21.478 16.0458 21.366 16.1898C21.254 16.3338 21.102 16.4218 20.91 16.4538H4.25401C4.09401 16.4538 3.94201 16.3978 3.79801 16.2858C3.65401 16.1738 3.56601 16.0218 3.53401 15.8298V4.45382H1.27801C1.08601 4.45382 0.92601 4.39782 0.79801 4.28582C0.67001 4.17382 0.59001 4.02182 0.55801 3.82982L0.51001 3.68582C0.51001 3.52582 0.56601 3.37382 0.67801 3.22982C0.79001 3.08582 0.94201 2.99782 1.13401 2.96582H4.25401ZM20.046 7.42982H5.02201V14.9658H20.046V7.42982Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_555_604">
                      <rect
                        width="24.02"
                        height="24"
                        fill="white"
                        transform="matrix(1 0 0 -1 0.5 24.3258)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="absolute inline-flex items-center justify-center  w-[20px] h-[20px] text-xs font-medium text-black bg-[#FFC220] border border-[#000] rounded-full -top-1 -right-2">
                  {userLoginData?.data ? name : "0"}
                </div>
              </div>
              <h3 className="pl-3 text-[10px] text-white">
                {userLoginData?.data
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "NGN",
                    }).format(total ? total : 0.0)
                  : "0.0"}
              </h3>
            </NavLink>
          </div>
        </div>
      </nav>

      <nav className="bg-[#0071BC] z-20 fixed w-full block md:hidden ">
        <div
          className={`items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow bg-[#0071BC] absolute top-0 left-0 right-0 h-auto z-3 rounded transition-transform duration-500 ${
            collapseShow === "hidden"
              ? "-translate-x-full"
              : "p-5 mr-5 translate-x-0"
          }`}
        >
          {/* Collapse header */}
          <div className="md:min-w-full md:hidden block pb-1 mb-4  ">
            <div className="flex justify-between ">
              <span
                className="flex justify-start cursor-pointer gap-1"
                onClick={() => setCollapseShow("hidden")}
              >
                <IoArrowBack
                  style={{ color: "#333333" }}
                  className="bg-gray-200 text-xs text-gray-500 rounded-full p-1 h-6 w-6"
                />{" "}
                <span className="text-white ml-2">Back</span>
              </span>
            </div>
          </div>

          <ul className="flex-col list-none flex bg-[#0071BC]  md:mt-1 mt-2">
            <li className="items-center mt-5 mx-3 mb-3">
              <NavLink
                onClick={() => setCollapseShow("hidden")}
                style={{
                  backgroundColor:
                    window.location.href.indexOf("/") !== -1
                      ? "rgba(255, 255, 255, 0.1)"
                      : "text-black hover:text-blueGray-500",
                }}
                className={
                  "text-xs cursor-pointer pl-3 block " +
                  (window.location.href.indexOf("/") !== -1
                    ? "text-white rounded-[8px]"
                    : "text-white border border-white/[0.1] rounded-[8px] hover:bg-white/[0.1]")
                }
                to="/"
              >
                <span className="flex py-2.5 px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    className="mr-3 "
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      fill-rule="evenodd"
                      d="M7.435 1.25h9.13c.57 0 1.054 0 1.453.041c.426.044.82.14 1.192.37c.371.23.633.539.863.9c.215.34.432.772.687 1.282l.016.033c.01.02.019.039.027.06l1.403 3.547c.168.423.353.95.407 1.488c.055.552-.02 1.183-.453 1.73a2.753 2.753 0 0 1-1.41.945v9.604H22a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1 0-1.5h1.25v-9.604a2.754 2.754 0 0 1-1.41-.944c-.432-.548-.508-1.18-.453-1.73c.054-.54.24-1.066.406-1.489l1.404-3.548a.747.747 0 0 1 .027-.06l.016-.032c.255-.51.471-.943.687-1.282c.23-.361.492-.67.863-.9c.372-.23.766-.326 1.191-.37c.4-.041.884-.041 1.454-.041M18 10.888a2.75 2.75 0 0 0 1.25.758v9.604h-4v-2.782c0-.44 0-.82-.028-1.13c-.03-.33-.096-.656-.273-.963a2.251 2.251 0 0 0-.824-.824c-.307-.177-.633-.243-.962-.273c-.312-.028-.691-.028-1.13-.028h-.065c-.44 0-.82 0-1.13.028c-.33.03-.656.096-.963.273a2.25 2.25 0 0 0-.824.824c-.177.307-.243.633-.273.962c-.028.312-.028.691-.028 1.13v2.783h-4v-9.603a2.75 2.75 0 0 0 1.25-.76a2.742 2.742 0 0 0 2 .863c.788 0 1.499-.331 2-.863a2.742 2.742 0 0 0 2 .863c.788 0 1.499-.331 2-.863a2.742 2.742 0 0 0 2 .863c.788 0 1.499-.331 2-.863M10.25 21.25h3.5V18.5c0-.481 0-.792-.022-1.027c-.02-.225-.055-.307-.079-.348a.75.75 0 0 0-.274-.274c-.04-.024-.123-.058-.348-.079A12.776 12.776 0 0 0 12 16.75c-.481 0-.792 0-1.027.022c-.225.02-.307.055-.348.079a.75.75 0 0 0-.274.274c-.024.04-.059.123-.079.348c-.021.235-.022.546-.022 1.027zM6.75 9a1.25 1.25 0 0 0 2.5 0a.75.75 0 0 1 1.5 0a1.25 1.25 0 0 0 2.5 0a.75.75 0 0 1 1.5 0a1.25 1.25 0 0 0 2.5 0a.75.75 0 0 1 1.5 0a1.25 1.25 0 0 0 2.232.773c.114-.144.17-.342.138-.652c-.032-.322-.151-.688-.308-1.086L19.42 4.517c-.268-.535-.447-.89-.613-1.15c-.16-.252-.274-.361-.386-.43c-.111-.07-.26-.123-.557-.154c-.314-.032-.72-.033-1.336-.033H7.472c-.617 0-1.023 0-1.336.033c-.297.031-.446.085-.557.154c-.112.069-.226.178-.386.43c-.167.26-.345.615-.613 1.15L3.188 8.035c-.157.398-.276.764-.308 1.086c-.031.31.024.508.138.652A1.25 1.25 0 0 0 5.25 9a.75.75 0 0 1 1.5 0"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span
                    style={{ fontSize: "15px" }}
                    className=" font-normal mt-1"
                  >
                    {" "}
                    Home
                  </span>
                </span>
              </NavLink>
            </li>

            <li className="items-center mt-5 mx-3 mb-3">
              <NavLink
                onClick={() => setCollapseShow("hidden")}
                style={{
                  backgroundColor:
                    window.location.href.indexOf("/user/view-purchase") !== -1
                      ? "rgba(255, 255, 255, 0.1)"
                      : "text-black hover:text-blueGray-500",
                }}
                className={
                  "text-xs cursor-pointer pl-3 block " +
                  (window.location.href.indexOf("/user/view-purchase") !== -1
                    ? "text-white rounded-[8px]"
                    : "text-white border border-white/[0.1] rounded-[8px] hover:bg-white/[0.1]")
                }
                to="/user/view-purchase"
              >
                <span className="flex py-2.5 px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    className="mr-3 "
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m0 1a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1m-9-1a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m0 1a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1M18 6H4.27l2.55 6H15c.33 0 .62-.16.8-.4l3-4c.13-.17.2-.38.2-.6a1 1 0 0 0-1-1m-3 7H6.87l-.77 1.56L6 15a1 1 0 0 0 1 1h11v1H7a2 2 0 0 1-2-2a2 2 0 0 1 .25-.97l.72-1.47L2.34 4H1V3h2l.85 2H18a2 2 0 0 1 2 2c0 .5-.17.92-.45 1.26l-2.91 3.89c-.36.51-.96.85-1.64.85"
                    />
                  </svg>
                  <span
                    style={{ fontSize: "15px" }}
                    className=" font-normal mt-1"
                  >
                    {" "}
                    Orders
                  </span>
                </span>
              </NavLink>
            </li>

            {/* <li className="items-center mt-5 mx-3 mb-3">
              <NavLink
                onClick={() => setCollapseShow("hidden")}
                style={{
                  backgroundColor: (window.location.href.indexOf("/user/wallet") !== -1
                    ? "rgba(255, 255, 255, 0.1)"
                    : "text-black hover:text-blueGray-500")
                }}
                className={
                  "text-xs cursor-pointer pl-3 block " +
                  (window.location.href.indexOf("/user/wallet") !== -1
                    ? "text-white rounded-[8px]"
                    : "text-white border border-white/[0.1] rounded-[8px] hover:bg-white/[0.1]")
                }
                to="/user/wallet"
              >
                <span className="flex py-2.5 px-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" className="mr-3 " viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1" /><path d="M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4" /></g></svg>
                  <span style={{ fontSize: '15px' }} className=" font-normal mt-1"> Wallet</span>
                </span>

              </NavLink>
            </li> */}

            <li className="items-center mt-5 mx-3 mb-3">
              <NavLink
                onClick={() => setCollapseShow("hidden")}
                style={{
                  backgroundColor:
                    window.location.href.indexOf("/user/wishlist") !== -1
                      ? "rgba(255, 255, 255, 0.1)"
                      : "text-black hover:text-blueGray-500",
                }}
                className={
                  "text-xs cursor-pointer pl-3 block " +
                  (window.location.href.indexOf("/user/wishlist") !== -1
                    ? "text-white rounded-[8px]"
                    : "text-white border border-white/[0.1] rounded-[8px] hover:bg-white/[0.1]")
                }
                to="/user/wishlist"
              >
                <span className="flex py-2.5 px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    className="mr-3 "
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      d="m5 10l3 3l6-6M5 24l3 3l6-6M5 38l3 3l6-6m7-11h22M21 38h22M21 10h22"
                    />
                  </svg>
                  <span
                    style={{ fontSize: "15px" }}
                    className=" font-normal mt-1"
                  >
                    {" "}
                    Wishlist
                  </span>
                </span>
              </NavLink>
            </li>

            {userLoginData?.token ? (
              <li className="items-center mt-[45vh] mx-3 mb-[20vh] pl-3 pb-6">
                <span
                  className={
                    "text-xs cursor-pointer block bg-[#FF0000] w-fit px-3 rounded-md "
                  }
                  onClick={logOut}
                >
                  <span className="flex py-2  cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      className="mr-3 "
                      viewBox="0 0 24 24"
                    >
                      <path fill="white" d="M16 13v-2H7V8l-5 4l5 4v-3z" />
                      <path
                        fill="white"
                        d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"
                      />
                    </svg>
                    <span className=" text-[15px] mt-1 font-normal text-white">
                      Log out
                    </span>
                  </span>
                </span>
              </li>
            ) : (
              <li className="items-center mt-[45vh] mb-[20vh] mx-3 ">
                <NavLink
                  onClick={() => setCollapseShow("hidden")}
                  style={{
                    backgroundColor:
                      window.location.href.indexOf("/sign-in") !== -1
                        ? "rgba(255, 255, 255, 0.1)"
                        : "text-black hover:text-blueGray-500",
                  }}
                  className={
                    "text-xs cursor-pointer pl-3 block " +
                    (window.location.href.indexOf("/sign-in") !== -1
                      ? "text-white rounded-[8px]"
                      : "text-white rounded-[8px] hover:bg-white/[0.1]")
                  }
                  to="/sign-in"
                >
                  <span className="flex py-2.5 px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      className="mr-3 "
                      viewBox="0 0 24 24"
                    >
                      <path fill="white" d="M16 13v-2H7V8l-5 4l5 4v-3z" />
                      <path
                        fill="white"
                        d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"
                      />
                    </svg>
                    <span
                      style={{ fontSize: "15px" }}
                      className=" font-normal mt-1"
                    >
                      {" "}
                      Sign In
                    </span>
                  </span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>

        <div className="flex justify-between py-4 ">
          <div className="flex justify-start gap-2">
            <span className="ml-2 mt-1">
              <button
                className="cursor-pointer text-black md:hidden text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                type="button"
                onClick={() => setCollapseShow(" mr-5 py-4 px-3 ")}
              >
                <b className="fas fa-bars text-3xl">
                  <svg
                    width="21"
                    height="15"
                    viewBox="0 0 21 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.92857 0H9.07143C9.45031 0 9.81367 0.158035 10.0816 0.43934C10.3495 0.720644 10.5 1.10218 10.5 1.5C10.5 1.89782 10.3495 2.27936 10.0816 2.56066C9.81367 2.84196 9.45031 3 9.07143 3H1.92857C1.54969 3 1.18633 2.84196 0.918419 2.56066C0.65051 2.27936 0.5 1.89782 0.5 1.5C0.5 1.10218 0.65051 0.720644 0.918419 0.43934C1.18633 0.158035 1.54969 0 1.92857 0ZM11.9286 12H19.0714C19.4503 12 19.8137 12.158 20.0816 12.4393C20.3495 12.7206 20.5 13.1022 20.5 13.5C20.5 13.8978 20.3495 14.2794 20.0816 14.5607C19.8137 14.842 19.4503 15 19.0714 15H11.9286C11.5497 15 11.1863 14.842 10.9184 14.5607C10.6505 14.2794 10.5 13.8978 10.5 13.5C10.5 13.1022 10.6505 12.7206 10.9184 12.4393C11.1863 12.158 11.5497 12 11.9286 12ZM1.92857 6H19.0714C19.4503 6 19.8137 6.15804 20.0816 6.43934C20.3495 6.72064 20.5 7.10218 20.5 7.5C20.5 7.89782 20.3495 8.27936 20.0816 8.56066C19.8137 8.84196 19.4503 9 19.0714 9H1.92857C1.54969 9 1.18633 8.84196 0.918419 8.56066C0.65051 8.27936 0.5 7.89782 0.5 7.5C0.5 7.10218 0.65051 6.72064 0.918419 6.43934C1.18633 6.15804 1.54969 6 1.92857 6Z"
                      fill="white"
                    />
                  </svg>
                </b>
                <span className="text-black "> </span>
              </button>
            </span>

            <span>
              <Link to={"/"}>
                <img
                  src="./images/white-logo.png"
                  width={"100px"}
                  className=""
                  alt="mart Logo"
                />
              </Link>
            </span>
          </div>

          <div className="flex gap-4 mr-3">
            <div className="flex space-x-2">
              <div className="mt-3">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 15.3258C1 14.2649 1.42143 13.2475 2.17157 12.4974C2.92172 11.7472 3.93913 11.3258 5 11.3258H13C14.0609 11.3258 15.0783 11.7472 15.8284 12.4974C16.5786 13.2475 17 14.2649 17 15.3258C17 15.8562 16.7893 16.3649 16.4142 16.74C16.0391 17.1151 15.5304 17.3258 15 17.3258H3C2.46957 17.3258 1.96086 17.1151 1.58579 16.74C1.21071 16.3649 1 15.8562 1 15.3258Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 7.32581C10.6569 7.32581 12 5.98266 12 4.32581C12 2.66895 10.6569 1.32581 9 1.32581C7.34315 1.32581 6 2.66895 6 4.32581C6 5.98266 7.34315 7.32581 9 7.32581Z"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
              {userLoginData?.token ? (
                <NavLink to={"/user/profile"}>
                  <h3 className="text-[14px] font-normal mt-2 cursor-pointer text-white">
                    Hi, {userLoginData?.data?.first_name}
                  </h3>
                </NavLink>
              ) : (
                <NavLink to={"/sign-in"}>
                  <h3
                    style={{ color: "white" }}
                    className="text-[11.6px] text-white font-normal"
                  >
                    Sign in
                  </h3>
                  <h3
                    style={{ color: "white" }}
                    className="text-[14px] text-white font-semibold"
                  >
                    Account
                  </h3>
                </NavLink>
              )}
            </div>
            <NavLink to={"/view-cart"}>
              <div className="relative flex justify-end ">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_555_604)">
                    <path
                      d="M16.494 17.2218C17.038 17.2218 17.486 17.3978 17.838 17.7498C18.19 18.1018 18.366 18.5418 18.366 19.0698C18.366 19.5978 18.19 20.0458 17.838 20.4138C17.486 20.7818 17.046 20.9658 16.518 20.9658C15.99 20.9658 15.542 20.7818 15.174 20.4138C14.806 20.0458 14.622 19.5978 14.622 19.0698C14.622 18.5418 14.806 18.1018 15.174 17.7498C15.542 17.3978 15.982 17.2218 16.494 17.2218ZM7.51801 17.2218C8.03001 17.2218 8.47001 17.3978 8.83801 17.7498C9.20601 18.1018 9.39001 18.5418 9.39001 19.0698C9.39001 19.5978 9.20601 20.0458 8.83801 20.4138C8.47001 20.7818 8.03001 20.9658 7.51801 20.9658C7.00601 20.9658 6.56601 20.7818 6.19801 20.4138C5.83001 20.0458 5.64601 19.5978 5.64601 19.0698C5.64601 18.5418 5.83001 18.1018 6.19801 17.7498C6.56601 17.3978 7.00601 17.2218 7.51801 17.2218ZM4.25401 2.96582C4.44601 2.96582 4.61401 3.02182 4.75801 3.13382C4.90201 3.24582 4.99001 3.39782 5.02201 3.58982V5.94182H20.766C20.958 5.94182 21.118 5.99782 21.246 6.10982C21.374 6.22182 21.454 6.37382 21.486 6.56582L21.534 15.6858C21.534 15.8778 21.478 16.0458 21.366 16.1898C21.254 16.3338 21.102 16.4218 20.91 16.4538H4.25401C4.09401 16.4538 3.94201 16.3978 3.79801 16.2858C3.65401 16.1738 3.56601 16.0218 3.53401 15.8298V4.45382H1.27801C1.08601 4.45382 0.92601 4.39782 0.79801 4.28582C0.67001 4.17382 0.59001 4.02182 0.55801 3.82982L0.51001 3.68582C0.51001 3.52582 0.56601 3.37382 0.67801 3.22982C0.79001 3.08582 0.94201 2.99782 1.13401 2.96582H4.25401ZM20.046 7.42982H5.02201V14.9658H20.046V7.42982Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_555_604">
                      <rect
                        width="24.02"
                        height="24"
                        fill="white"
                        transform="matrix(1 0 0 -1 0.5 24.3258)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <div className="absolute inline-flex items-center justify-center  w-[20px] h-[20px] text-xs font-medium text-black bg-[#FFC220] border border-[#000] rounded-full -top-1 -right-2">
                  {userLoginData?.data ? name : "0"}
                </div>
              </div>
              <h3 className="text-[10px] text-white">
                {userLoginData?.data
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "NGN",
                    }).format(total ? total : 0.0)
                  : "0.0"}
              </h3>
            </NavLink>
          </div>

          {/* <div className='flex mt-1 mr-3'>
              <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.024 1.984H5.024V5.984H1.024V1.984ZM0.512 0.991995C0.362667 0.991995 0.24 1.04 0.144 1.13599C0.048 1.23199 0 1.35466 0 1.50399V6.496C0 6.64533 0.048 6.76799 0.144 6.864C0.24 6.96 0.362667 7.008 0.512 7.008H5.504C5.65333 7.008 5.776 6.96 5.872 6.864C5.968 6.76799 6.016 6.64533 6.016 6.496V1.50399C6.016 1.35466 5.968 1.23199 5.872 1.13599C5.776 1.04 5.65333 0.991995 5.504 0.991995H0.512ZM9.024 1.984V5.984H13.024V1.984H9.024ZM8.512 0.991995H13.504C13.6533 0.991995 13.776 1.04 13.872 1.13599C13.968 1.23199 14.016 1.35466 14.016 1.50399V6.496C14.016 6.64533 13.968 6.76799 13.872 6.864C13.776 6.96 13.6533 7.008 13.504 7.008H8.512C8.36267 7.008 8.24 6.96 8.144 6.864C8.048 6.76799 8 6.64533 8 6.496V1.50399C8 1.35466 8.048 1.23199 8.144 1.13599C8.24 1.04 8.36267 0.991995 8.512 0.991995ZM1.024 9.984V13.984H5.024V9.984H1.024ZM0.512 8.992H5.504C5.65333 8.992 5.776 9.04 5.872 9.136C5.968 9.232 6.016 9.35466 6.016 9.504V14.496C6.016 14.6453 5.968 14.768 5.872 14.864C5.776 14.96 5.65333 15.008 5.504 15.008H0.512C0.362667 15.008 0.24 14.96 0.144 14.864C0.048 14.768 0 14.6453 0 14.496V9.504C0 9.35466 0.048 9.232 0.144 9.136C0.24 9.04 0.362667 8.992 0.512 8.992ZM9.024 9.984V13.984H13.024V9.984H9.024ZM8.512 8.992H13.504C13.6533 8.992 13.776 9.04 13.872 9.136C13.968 9.232 14.016 9.35466 14.016 9.504V14.496C14.016 14.6453 13.968 14.768 13.872 14.864C13.776 14.96 13.6533 15.008 13.504 15.008H8.512C8.36267 15.008 8.24 14.96 8.144 14.864C8.048 14.768 8 14.6453 8 14.496V9.504C8 9.35466 8.048 9.232 8.144 9.136C8.24 9.04 8.36267 8.992 8.512 8.992Z" fill="#F2B705" />
              </svg>

              <h3 className='text-[15px]'>Categories</h3>
            </div> */}
        </div>

        <div className="flex justify-center my-5 mx-1">
          <div>
            {/* <form onSubmit={(e: any) => searchFilter('', e)} >
              <div className="flex justify-start">
                <div className="relative w-12/12] pb-3">
                  <input type="text" defaultValue={search} onChange={e => setSearch(e.target.value)} id="search-dropdown" className="block p-2.5 w-[80vw] z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Type Keyword here..." />
                  <button type="submit" className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white rounded-r-lg border border-blue-200" style={{ backgroundColor: '#2196F3' }}>
                    <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    <span className="sr-only">Search</span>
                  </button>
                </div>
              </div>
            </form> */}
            <div className="relative w-12/12] pb-3">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                // placeholder="Search products or categories..."
                className="block p-2.5 w-[80vw] z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Type Keyword here..."
              />
              {loader && (
                <div className="absolute right-3 top-3">
                  <svg
                    className="animate-spin h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.959 7.959 0 014 12H2c0 2.386.832 4.57 2.207 6.293l1.793-1.002z"
                    ></path>
                  </svg>
                </div>
              )}
              {error && <p className="text-red-500 mt-2">{error}</p>}
              {suggestions.length > 0 && (
                <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                  {suggestions.map((suggestion) => {
                    // Remove "- null" if it appears at the end of suggestion.highlight
           
                    return (
                      <NavLink
                        to={`/view-product/${suggestion.id}`}
                        key={suggestion.id}
                      >
                        <div
                          className="p-3 hover:bg-gray-100 cursor-pointer"
                        >{suggestion?.name}</div>
                      </NavLink>
                    );
                  })}
                </div>
              )}
              {!loader && !error && search && suggestions.length === 0 && (
                <p className="text-gray-500 mt-2">No results found</p>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* <section className=" px-12 py-10" >
        <Carousel cols={7} rows={1} gap={20} loop>
          {cate?.data?.map(
            (datas: any, index: any) => (
              <Carousel.Item>
              
                <span className=' cursor-pointer flex flex-col'>
                  <span onClick={() => { setCategory(datas?.cat_name); getCategory(datas?.cat_name) }} className='border mx-4  flex justify-center py-3 max-h-20 w-28 rounded-lg border-[#63bdf5]'>
                    <img src={datas?.cat_image} alt="M-Mart Logo" />
                  </span>
                  <h3 className='text-[14px] pl-10'>{datas?.cat_name}</h3>
                </span>

               
              </Carousel.Item>

            )
          )
          }
        </Carousel>

      </section> */}

      <section className=" px-2 pt-[180px] py-3 md:hidden">
        <div className="">
          <Slider {...settings}>
            {cate?.data?.map((datas: any, index: any) => (
              <div
                key={index}
                className="flex-none px-2 cursor-pointer"
                onClick={() => {
                  setCategory(datas?.cat_name);
                  getCategory(datas?.cat_name);
                }}
              >
                <img
                  src={datas?.cat_image}
                  alt={`Slide ${index}`}
                  className="w-full h-full object-cover"
                />
                <span className="text-[12px] flex justify-center">
                  {datas?.cat_name}
                </span>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section className=" px-4 pb-10 pt-[105px] py-3 md:block hidden">
        <div className="">
          <Slider {...settings2}>
            {cate?.data?.map((datas: any, index: any) => (
              <div
                key={index}
                className="flex-none px-4 cursor-pointer"
                onClick={() => {
                  setCategory(datas?.cat_name);
                  getCategory(datas?.cat_name);
                }}
              >
                <img
                  src={datas?.cat_image}
                  alt={`Slide ${index}`}
                  className="w-full h-full object-cover"
                />
                <span className="text-[12px] flex justify-center">
                  {datas?.cat_name}
                </span>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <div className="md:px-8 px-4">
        <section className="md:px-4 pt-2">
          <div className="flex flex-col">
            <div className="py-4">
              <span>
                {" "}
                Search:{" "}
                <span className="text-[21px] font-bold">{searchResult}</span>
              </span>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-3 ">
              {!loader ? (
                products.data?.data?.length >= 1 ? (
                  products?.data?.data?.map((data: any, index: number) => (
                    <span className=" md:w-full hover:bg-[#f1f6f9] border border-[#E6F1FC] rounded-lg p-2 cursor-pointer">
                      <span
                        onClick={() => toggleWishlist(data)}
                        className="flex justify-end cursor-pointer"
                      >
                        {userWishLists.has(data.id.toString()) ? (
                          <FaHeart className="text-blue-700" />
                        ) : (
                          <FaRegHeart className="text-blue-700" />
                        )}
                      </span>

                      <NavLink to={`/view-product/${data?.id}`}>
                        <p
                          className="mb-2 tracking-tight m-2 p-2 bg-[#F4FBFF] h-44"
                          style={{
                            fontSize: "16px",
                            color: "#595959",
                            backgroundImage: `url(${data?.product_image_1})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        >
                          {data?.link_info?.message}
                        </p>
                        <div>
                          {data?.is_under_deal ? (
                            <div className="flex flex-col">
                              <span className="line-through mr-2 text-gray-500">
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "NGN",
                                }).format(data?.original_price)}
                              </span>
                              <h3 className="text-[16px] font-semibold">
                                NGN{data?.discounted_price}
                              </h3>
                            </div>
                          ) : (
                            <h3 className="text-[16px] font-semibold pt-2">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "NGN",
                              }).format(data?.product_price)}{" "}
                            </h3>
                          )}

                          <h5 className="text-[14px] tracking-tight text-gray-900 ">
                            {data?.product_name}
                          </h5>
                        </div>

                        {/* <div className="flex justify-between">
                          <span className="rounded-[2px] text-[11px] text-[#004F9A] bg-[#E6F1FC] px-1 py-1.5">
                            same day delivery
                          </span>
                        </div> */}
                      </NavLink>
                    </span>
                  ))
                ) : (
                  <div className="md:p-6 min-w-[90vw]">
                    <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-700"></div>
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                    <div className=" bg-gray-400 rounded-full text-center text-white mb-2.5">
                      {" "}
                      No Product Availabe
                    </div>

                    <div className="flex items-center mt-4 space-x-3"></div>
                  </div>
                )
              ) : (
                <div
                  className="p-4 rounded border border-gray-200 shadow animate-pulse md:p-6 dark:border-gray-700"
                  style={{ height: "70vh", width: "92vw" }}
                >
                  <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-400"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-48 mb-4"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400"></div>
                  <div className="flex items-center mt-4 space-x-3"></div>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>

            <div className=" my-4 mt-10 flex justify-end ">
              {products?.data?.links
                ?.filter((item: any, idx: any) => idx < 1000)
                .map((datas: any, index: any) => (
                  <button
                    onClick={() =>
                      paginator(
                        datas?.label == "Next &raquo;"
                          ? datas?.url.charAt(datas?.url.length - 1)
                          : datas?.label === "&laquo; Previous"
                          ? datas?.url.charAt(datas?.url.length - 1)
                          : datas?.label
                      )
                    }
                    disabled={datas?.active}
                    className={
                      "mx-1 py-1 px-1 " +
                      (datas?.active == false
                        ? "bg-gray-300 text-black "
                        : `bg-['#0071BC'}] text-white`)
                    }
                    style={{
                      backgroundColor: `${
                        datas?.active == false ? "rgb(209 213 219" : "#0071BC"
                      }`,
                    }}
                  >
                    {datas?.label == "&laquo; Previous"
                      ? "< Previous"
                      : datas?.label === "Next &raquo;"
                      ? "Next  >"
                      : datas?.label}
                  </button>
                ))}
            </div>
          </div>

          {/* </div> */}
        </section>
      </div>

      <Footer />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Home;

import React from 'react'

export default function CardVouchers() {
  return (
    <div>
    <div className="bg-white mt-16">
      <div className="py-4 lg:px-10 px-6 ">
        <div className="py-3 font-[700] bg-[#0071BC] text-white p-2 rounded">
          <h1 className="text-xl font-semibold">Vouchers</h1>
        </div>
        <div className="border p-5">
          <div className="flex flex-col justify-center items-center py-20">
            <h2 className="font-bold text-base">
              You don't have any messages
            </h2>
            <h5 className="font-normal text-base text-gray-500">
              Here you will be able to see all the messages that we send you.
              Stay tuned
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { ProductApis } from '../../apis/productApis';
import { NavLink, useNavigate } from 'react-router-dom';

interface SearchResult {
  id: number;
  name: string;
  description?: string;
  type: 'product' | 'category';
  highlight: string;
}

const AutoCompleteSearch: React.FC = () => {
    const navigate = useNavigate();

  const [query, setQuery] = useState<string>('');
  const [suggestions, setSuggestions] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

// console.log(suggestions)
  const handleFetchSuggestions = useCallback(async (searchTerm: string) => {
    if (!searchTerm) {
      setSuggestions([]);
      return;
    }
  
    setIsLoading(true);
    setError(null);
  
    try {
      const response = await ProductApis.fetchSuggestions(searchTerm);
      setSuggestions(response.data.results);
      // console.log(response)
      // navigate('/product', { state: { searchMe: query } });

    } catch (err) {
      setError("");
      // navigate('/product', { state: { searchMe: query } });

    } finally {
      setIsLoading(false);
    }
  }, [query]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
        handleFetchSuggestions(query);
    }, 300);
 
    return () => clearTimeout(delayDebounce);
  }, [query, handleFetchSuggestions]);
// console.log(suggestions)
  return (
    <div>
      <div className='hidden md:block'>
    <div className="relative w-auto md:w-[200px] lg:w-[500px]">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search everything at M Mart online and in store..."
        className="w-full p-3 border border-gray-300 bg-white rounded-full shadow-sm text-[#0071BC] focus:outline-none focus:border-white placeholder:text-gray-500"
      />
      {isLoading && (
        <div className="absolute right-3 top-3">
          <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.959 7.959 0 014 12H2c0 2.386.832 4.57 2.207 6.293l1.793-1.002z"></path>
          </svg>
        </div>
      )}
      {/* {error && <p className="text-red-500 mt-2">{error}</p>} */}
      {suggestions.length > 0 && (
        <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
         {suggestions.map((suggestion) => {


  return (
    <NavLink to={`/view-product/${suggestion.id}`} key={suggestion.id}>
      <div
        className="p-3 hover:bg-gray-100 cursor-pointer"
      >{suggestion?.name}</div>
    </NavLink>
  );
})}
        </div>
      )}
      {!isLoading && !error && query && suggestions.length === 0 && (
        <p className="text-white mt-2">No results found</p>
      )}
    </div>
    </div>

    <div className='md:hidden block'>
    <div className="relative w-12/12] pb-3">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search products here..."
        className="block p-2.5 w-[80vw] z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
/>
      {isLoading && (
        <div className="absolute right-3 top-3">
          <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.959 7.959 0 014 12H2c0 2.386.832 4.57 2.207 6.293l1.793-1.002z"></path>
          </svg>
        </div>
      )}
      {/* {error && <p className="text-red-500 mt-2">{error}</p>} */}
      {suggestions.length > 0 && (
        <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
    {suggestions.map((suggestion) => {
  // Remove "- null" if it appears at the end of suggestion.highlight


  return (
    <NavLink to={`/view-product/${suggestion.id}`} key={suggestion.id}>
      <div
        className="p-3 hover:bg-gray-100 cursor-pointer"
      >{suggestion?.name}</div>
    </NavLink>
  );
})}

        </div>
      )}
      {!isLoading && !error && query && suggestions.length === 0 && (
        <p className="text-white mt-2">No results found</p>
      )}
    </div>
    </div>

    </div>
  );
};

export default AutoCompleteSearch;

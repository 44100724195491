import React, { useEffect, useRef, useState } from "react";
import { ProductsLiveApi } from "../apis/live/productsLiveApis";
import { Product } from "../types/products";
import { CartApis } from "../apis/cartApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { login } from "../reducer/loginSlice";
import { FaCartPlus, FaChevronLeft, FaChevronRight, FaHeart, FaRegHeart } from "react-icons/fa";
import { useNavigate, Link, useLocation, } from "react-router-dom";
import Rating from "./Cards/CardRating";
import { addToCart } from "./Cards/AddtoCart";
import axios from "axios";
import { BsCart2 } from "react-icons/bs";


interface HomeProductInfo {
  id: string;
  product_name: string;
  product_image_1: string;
  product_price: number;
  purchase_count: number
  frequently_purchased: string
  discounted_price: number;
  is_under_deal: boolean;
}


interface HomeProductProps {
  homeProductInfo: HomeProductInfo;
}
const FrequentlyPurchased = ({homeProductInfo}: HomeProductProps) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [status, setStatus] = useState<"idle" | "loading" | "failed">("idle");
  const [error, setError] = useState<string | null>(null);
  const routeLocation = useLocation();
  const scrollContainerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const sectionIndex = 0; // Define sectionIndex



  // const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({
  //   0: false,
  //   1: false,
  //   2: false,
  // });

  const [activeStars, setActiveStars] = useState<{ [key: number]: boolean[] }>(
    {}
  );
  

  const handleStarClick = async (productId: number, rating: number) => {
    if (!userLoginData?.token) {
      toast.error("You need to log in to submit a rating.");
      return;
    }
  
    // Ensure the rating is between 1 and 5
    const validRating = Math.min(5, Math.max(1, rating));
  
    try {
      await axios.post(
        `https://prod.mmartplus.com/api/products/${productId}/rate`,
        { rating: validRating }, // Send only 1-5
        { headers: { Authorization: `Bearer ${userLoginData.token}` } }
      );
  
      // Update UI state optimistically
      setActiveStars((prevStars) => ({
        ...prevStars,
        [productId]: Array(5).fill(false).map((_, i) => i < validRating),
      }));
  
      toast.success("Product rated successfully.");
    } catch (error) {
      console.error("Error submitting rating:", error);
      toast.success("You have already rated this product");
    }
  };


  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const userLoginData = useSelector((state: any) => state.data.login.value);
  const [userWishLists, setUserWishLists] = React.useState(new Set());

  React.useEffect(() => {
    
    CartApis.getAllList("")
      .then((response) => {
        if (response?.data) {
          const wishListIds = new Set(
            response?.data?.data.map((item: any) => item.item_id)
          );
          setUserWishLists(wishListIds);
        } else {
          dispatch(login([]));
        }
      })
      .catch(function (error) {
        console.error("Error fetching wishlist:", error);
      });
  }, []);

  const toggleWishlist = React.useCallback(
    (productInfos: any) => {
      if (!userLoginData?.id) {
        navigate("/sign-in");
        return;
      }

      const productId = productInfos?.id;
      const isWished = userWishLists.has(productId.toString());

      if (isWished) {
        // Remove from wishlist
        CartApis.deleteWishlist(productId)
          .then((response) => {
            if (response?.data) {
              setUserWishLists((prev) => {
                const updatedSet = new Set(prev);
                updatedSet.delete(productId);
                return updatedSet;
              });
              toast.success("Removed from wishlist.");
            }
          })
          .catch(function (error) {
            console.error("Error removing from wishlist:", error);
            toast.error("Error removing from wishlist.");
          });
      } else {
        // Add to wishlist
        let data = {
          product_id: productId,
          price: productInfos?.product_price,
          product_name: productInfos?.product_name,
          category: productInfos?.category,
          product_img: productInfos?.product_image_1
            ? productInfos?.product_image_1
            : "no image",
          user_id: userLoginData?.id,
        };
        CartApis.createWishlist(data)
          .then((response) => {
            if (response?.data) {
              setUserWishLists((prev) =>
                new Set(prev).add(productId.toString())
              );
              toast.success("Added to wishlist.");
            }
          })
          .catch(function (error) {
            console.error("Error adding to wishlist:", error);
            toast.error("Error adding to wishlist.");
          });
      }
    },
    [userLoginData, userWishLists, navigate]
  );

  useEffect(() => {
    const fetchProducts = async () => {
      setStatus('loading');
      try {
        const productApi = new ProductsLiveApi();
        const response: any = await productApi.fetchFrequentlyPurchased();
        // console.log('API response:', response); // Log the API response
        setProducts(response.data.data.frequently_purchased);
        // console.log('Products state:', response.data.data.frequently_purchased); // Log the products state
        setStatus('idle');
      } catch (err) {
        // console.error('Failed to fetch products:', err); // Log the error
        setError('Failed to fetch products');
        setStatus('failed');
      }
    };
    fetchProducts();
  }, []);

  const scrollLeft = (index: number) => {
    if (scrollContainerRefs.current[index]) {
      scrollContainerRefs.current[index]!.scrollBy({
        left: -240,
        behavior: "smooth",
      });
    }
  };
  
  const scrollRight = (index: number) => {
    if (scrollContainerRefs.current[index]) {
      scrollContainerRefs.current[index]!.scrollBy({
        left: 240,
        behavior: "smooth",
      });
    }
  };
  

  if (status === "loading") return <div></div>;
  if (status === "failed") return <div>Error: {error}</div>;
  
  const handleAddToCart = (product: any) => {
      addToCart(product, userLoginData, routeLocation, navigate, dispatch);
    };

    
    return (
        <div className="relative py-4 px-4 mt-10">
          <h3 className="font-bold text-3xl mb-5">Frequently Purchased</h3>
            <button
          onClick={() => scrollLeft(sectionIndex)}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white shadow-lg p-2 rounded-full"
              aria-label="scroll left"
            >
              <FaChevronLeft className="text-black" />
            </button>
          <div className="overflow-x-auto scrollbar-none flex  gap-4" ref={(el) => (scrollContainerRefs.current[sectionIndex] = el)}>
            {Array.isArray(products) && products.map((product: any) => (
              <div
                key={product.id}
                className="p-4 border shadow-md"
                style={{
                  width: "240px",
                  flex: "0 0 auto",
                  height: "auto",
                }}
              >
                <div style={{ height: "150px", overflow: "hidden" }}>
                  <span
                    onClick={() => toggleWishlist(product)}
                    className="flex justify-end cursor-pointer"
                  >
                    {userWishLists.has(product.id.toString()) ? (
                      <FaHeart className="text-blue-700" />
                    ) : (
                      <FaRegHeart className="text-blue-700" />
                    )}
                  </span>
                  <div className="flex flex-col justify-center items-center">
                  <Link to={`/view-product/${product.id}`}>
                    <img
                      src={product.product_image_1}
                      alt={product.product_name}
                      className=" h-40  rounded-md"
                      />
                  </Link>
                      </div>
                </div>
<span
                      className="flex rounded-ful bg-[#027DC justify-left  cursor-pointer"
                      onClick={() => handleAddToCart(product)}
                      >
                      <div className="p-2 rounded-full bg-[#0071BC]">
                      <BsCart2 className="h-4 w-4" color="#fff" />
                      </div>
                        <h1 className="text-[#0071BC] items-center  justify-center flex ml-2">add to cart</h1>
                    </span>
                <p className="text-black font-bold lg:mt- mt-3">₦{parseInt(product.product_price.replace('N', '')).toLocaleString()}</p>
                <p className="text-black font-bold lg:mt- mt-3 cursor-pointer">
                  <Link to={`/view-product/${product.id}`}>{product.product_name.slice(0, 15)}...</Link>
                </p>
                <div className="lg:pt-3 pt-3">
                  <Rating
                   productId={product.id}
                    activeStars={activeStars[product.id] || [false, false, false, false, false]}
                    onStarClick={(index) => handleStarClick(product.id, index)}
                  />
                   {/* <Rating
        activeStars={new Array(5).fill(false).map((_, index) => index < product.rating)}
        onStarClick={(index) => handleStarClick(product.id, index)}
      /> */}
                </div>
                <span className=" cursor-pointer bg-blue-50 text-blue-700 w-40 mt-5 hidden sm:block">
                  <h2>20 - 30mins delivery</h2>
                </span>
              </div>
            ))}
          </div>
            <button
          onClick={() => scrollRight(sectionIndex)}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white shadow-lg p-2 rounded-full"
              aria-label="scroll right"
            >
              <FaChevronRight className="text-black" />
            </button>
          <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        </div>
      );
    };
    
    export default FrequentlyPurchased;
    


import React from "react";

// components

import CardCategories from "../Cards/CardCategories";
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";

export default function PurchaseList() {
  return (
    <>
  <Sidebar title="Category List"/>
      <div className="relative md:ml-36 bg-white">
      <AdminNavbar title="Category List" />
      <div className="flex flex-wrap mt-1">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-1">
          <CardCategories />
        </div>
      </div>
      </div>
    </>
  );
}

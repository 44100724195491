import React, { useEffect } from 'react'
import Categories from './Categories';
import MostViewed from './Groups/MostViewed';
import { ProductApis } from '../apis/productApis';
import { AxiosResponse } from 'axios';
import { SvgElement, icontypesEnum } from './assets/svgElement';
import { Link, NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FaEye, FaWhatsapp } from 'react-icons/fa';
import configs from '../configs';
import Modal from 'react-responsive-modal';
import { PaymentApis } from '../apis/paymentApis';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from "redux";
import { login } from '../reducer/loginSlice'

//@ts-ignore
import { PhoneInput } from 'react-contact-number-input';
import { AuthApis } from '../apis/authApis';
import { CartApis } from '../apis/cartApis';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { hideAll } from 'tippy.js';
import UserSidebar from './Sidebar/UserSidebar';
// import Navbar from './Navbars/AdminNavbar';
import Sidebar from './Sidebar/Sidebar';
import { IoArrowBack } from 'react-icons/io5';
import { AiOutlineSetting } from 'react-icons/ai';
import { CategoryApis } from '../apis/CategoryApis';
import Footer from './Footers/Footer';
import CategoryLoader from './CategoryLoader';
import NavbarNew from './Navbars/NavbarNew';
import Navbar from './Navbars/Navbar';
import { PromotionApis } from '../apis/promotionApis';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HomeProduct from './product';
import FrequentlyPurchased from './frequentlyBought';
import Deals from './hotDeals';

function Category() {

  const userLoginData = useSelector((state: any) => state.data.login.value);

// console.log(userLoginData)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch: Dispatch = useDispatch();

  const [products, setProducts] = React.useState<any>([]);
  const [loc, setLoc] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [fetchCategory, setFetchCategory] = React.useState('');
  const [loader, setLoader] = React.useState<boolean>(false);

  // let [fullName, setFullName] = React.useState('');
  // let [email, setEmail] = React.useState('');
  const [location, setLocation] = React.useState('');
  // let [phoneNumber, setPhoneNumber] = React.useState<any>('');
  // let [productQty, setProductQty] = React.useState<any>('');
  // const [collapseShow, setCollapseShow] = React.useState("hidden");

  // let [target, setTarget] = React.useState<Element>();

  // let [visible, setVisible] = React.useState(false);
  // let [value, setvalue] = React.useState<any>('');

  // function togglePaymentModal(value2: any) {
  //   setvalue(value2)
  //   setVisible(true)
  // }

  const params = useParams();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  let [data, setdata] = React.useState<any>([]);
  let [value, setvalue] = React.useState('');
  let [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
  let [visible, setVisible] = React.useState(false);

  let [valueStatus, setvalueStatus] = React.useState('');
  let [toggleStatusModal, setToggleStatusModal] = React.useState(false);
    // Load data when the component mounts or page refreshes

    React.useEffect(() => {
      setLoader(true);
  
      PromotionApis.getAllPromotions().then((response) => {
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data);
          setLoader(false);
        } else {
          // dispatch(login([]));
        }
      }).catch((error:any) => {
        console.log(error.response.data);
        setLoader(false);
      });
    
    }, []); // Trigger effect when 'effect' or URL changes
    

  const [name, setname] = React.useState('');
  const [total2, setTotal2] = React.useState<any>('');
  const [total, setTotal] = React.useState<any>('');

  React.useEffect(() => {
    CartApis.getCartCount().then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setname(response?.data?.cart);
          setTotal2(response?.data?.total)
        } else {
          dispatch(login([]))
        }
      }
    )


    CategoryApis.getCategory('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setTotal(response?.data?.data)
        } else {
          dispatch(login([]))
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
    })

  }, []);



  if (searchParams.get('status') == 'cancelled') {
    navigate(`/store/${params?.storeId}`);
  }


  React.useEffect(() => {
    setLoader(true);
    const query: any = {
      search: search,
      name: loc,
      categories: category
    };
    ProductApis.getAllProducts('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setProducts(response.data)
          setLoader(false);

          // console.log(response?.data?.data);
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
    })

  }, [category]);


  const logOut = () => {
    dispatch(login([]))
    AuthApis.logout('').then(
      (response: AxiosResponse<any>) => {
        if (response) {
          navigate('/');

        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
      console.log("new error");
    })

  };


  // React.useEffect(() => {
  //   setLoader(true);
  //   const query: any = {
  //     search: search,
  //     name: loc,
  //     categories: category
  //   };
  //   ProductApis.getAllProducts('', query).then(
  //     (response: AxiosResponse<any>) => {
  //       if (response?.data) {
  //         setProducts(response.data)
  //         console?.log(response?.data)
  //         setLoader(false);

  //         // console.log(response?.data?.data);
  //       }
  //     }
  //   ).catch(function (error: any) {
  //     // handle error
  //     console.log(error.response.data);
  //   })

  // }, [loc]);

  function Locate(location: any) {
    setLoader(true);
    setLoc(location);
    const query: any = {
      search: search,
      name: location,
      categories: category
    };
    ProductApis.getAllProducts('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setProducts(response.data)
          setLoader(false);
          setFetchCategory(search !== '' ? search : category)
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
      console.log("new error");
    })

  }


  function cat(catItem: any) {
    setLoader(true);
    setLoc(location);
    const query: any = {
      search: search,
      name: loc,
      categories: catItem
    };
    ProductApis.getAllProducts('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setProducts(response.data)
          setLoader(false);
          setFetchCategory(search !== '' ? search : catItem)
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
      console.log("new error");
    })

  }


  const paginator = React.useCallback(
    (value: any) => {
      setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: search,
        name: loc,
        categories: category
      };
      ProductApis.getAllProducts(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setProducts(response.data)
            setLoader(false);
            setFetchCategory(search !== '' ? search : category)
          }
        }
      ).catch(function (error: any) {
        // handle error
        console.log(error.response.data);
        console.log("new error");
      })

    }, [search, loc, category, fetchCategory]);






  const [dea, setDea] = React.useState<any>([]);
  const [searc, setSearc] = React.useState<any>('');
  const [loade, setLoade] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoader(true);
    const query = {
      search: searc,
      name: '',
      categories: ''
    };
    ProductApis.getAllDeals('', query).then(
      (response) => {
        if (response?.data) {
          // console.log(response.data)
          setDea(response.data)
          setLoade(false);
        }
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
    })

  }, []);
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault(); // Prevent the form from submitting by default
      
      if (search.trim() !== '') {
          navigate('/product', { state: { searchMe: search } });
      }
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // optional, default to 1.
    },
  };

  // Assuming this is inside your component
const activePromotions = data?.promotion_images?.filter((prom: any) => prom.status === "active") || [];

// Flatten the promotion images into a single array
const allImages = activePromotions.flatMap((prom: any) => prom.promotion_images.map((image: string) => ({
  image,
  promotionName: prom.promotion_name,
})));
  return (
    <div className=''>
<Navbar />

{!loader ? (
<div className='md:mt-0 -mt-3'>

{allImages.length > 0 ? (
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={{
          superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
          desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
          tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
          mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
        }}
        ssr={true}
        infinite={true}
        rtl={false}
        autoPlay={true}
        autoPlaySpeed={7000}
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        className="rounded-[12px]    "
        arrows={false}
      >
        {allImages.map((item:any, index:any) => (
          <div key={index} className="flex  md:p-3 md:px-8 px-4 w-full items-center justify-center">
            <img
              src={item.image}
              alt={item.promotionName}
              className="   md:h-[400px] h-[100px]  object-cover lg:h-[] " // Adjust the height and width as needed
            />
          </div>
        ))}
      </Carousel>
    ) : (
      ''
    )}

  </div>
) : (
  <div className="p-4  shadow animate-pulse md:p-6" >
  <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-400">

  </div>

  <span className="sr-only">Loading...</span>
</div>
)}

{!loader ? (
   <div className='md:px-8 px-4  '>


   <section className=" p-3" >
     <div className=' mx-auto flex space-x-8 overflow-x-auto py-3 scrollbar-none'>

       {total?.data?.length >= 1 && total?.data?.map(
         (datas: any, index: any) => (
           <Link to={'/product'} state={{ datas }}>
             <div className='border  flex justify-center lg:py-3 max-h-32 max-w-full rounded-full border-[#63bdf5]'>
               <img src={datas?.cat_image} className="w-[90px] h-[90px]  max-w-[290px] sm:w-24 sm:h-24 lg:w-32 lg:p-4 p-2 object-cover" alt="Samosex Logo" />
             </div>
             <h3 className='text-[14px] text-center'>{datas?.cat_name}</h3>
           </Link>

         )
       )
       }




     </div>
   </section>

<div>

  <HomeProduct homeProductInfo={products} />
</div>

<div className="div">
  <FrequentlyPurchased homeProductInfo={products}/>
</div>
<div className="div">
  <Deals/>
</div>
   

   {/* {dea?.deals?.length >= 1 ?
     <div className=' px-2 mb-28'>
       <section className=" pt-2" >

         <div className='flex flex-col'>
           <div className="flex justify-between">
             <h3 className='text-[21px] font-bold  pb-4'>Deals</h3>
             <Link to={'/deal'}>
               <h3 className='text-[14px] text-red-600 underline pb-4'>View More</h3>
             </Link>

           </div>
           <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-7 gap-3 '>
             {!loader ? (
               dea?.deals?.length >= 1 ?
                 dea?.deals?.filter((data: any, index: any) => index <= 5)?.map(
                   (data: any, index: any) => (

                     // <div>
                     <Link to={`/view-product/${(data?.id)}`} state={products?.percent} className="w-[90vw] md:w-full hover:bg-[#f1f6f9] border border-[#E6F1FC] rounded-lg p-2 cursor-pointer">
                       <p className="mb-2 tracking-tight m-2 p-2 bg-[#F4FBFF] h-44" style={{ fontSize: '16px', color: '#595959', backgroundImage: `url(${data?.product_image_1})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition: 'center center' }}>{data?.link_info?.message}</p>
                       <div>
                         <h3 className='text-[14px] text-red-600 line-through pt-2'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((data?.product_price))} </h3>
                         <h3 className='text-[16px] font-semibold '>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(data?.product_price - ((data?.product_price) * (dea?.percent / 100)))} </h3>
                         <h5 className="text-[14px] tracking-tight font-semibold underline text-gray-900 ">{data?.product_name}</h5>
                       </div>

                     

                     </Link>
                     // </div>

                   )
                 )
                 :

                 <div className="md:p-6 min-w-[90vw]">
                   <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-700">

                   </div>
                   <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                   <div className=" bg-gray-400 rounded-full text-center text-white mb-2.5"> No Product Availabe</div>

                   <div className="flex items-center mt-4 space-x-3">

                   </div>

                 </div>

             )
               :
               <div className="p-4 rounded border border-gray-200 shadow animate-pulse md:p-6 dark:border-gray-700" style={{ height: '70vh', width: '92vw' }}>
                 <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-400">

                 </div>
                 <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-48 mb-4"></div>
                 <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
                 <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
                 <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400"></div>
                 <div className="flex items-center mt-4 space-x-3">

                 </div>
                 <span className="sr-only">Loading...</span>
               </div>
             }


           </div>



         </div>

    

       </section>
     </div>
     :
     ''
   } */}



 </div>
) : (
<CategoryLoader />
)}

   


      <Footer />





      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

    </div>
  );
}

export default Category;

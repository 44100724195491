import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//@ts-ignore
import Modal from "react-awesome-modal";
import { useSelector, useDispatch } from "react-redux";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { FaArrowLeft, FaCartPlus } from "react-icons/fa";

function WitdrawalRequest() {
  const userLoginData = useSelector((state) => state.data.login.value);
  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  let [visible2, setVisible2] = React.useState(false);
  let [orderStatus, setOrderStatus] = React.useState([]);
  const [selectedOrder, setSelectedOrder] = React.useState(null); // State to track selected order
  const [isLoading, setIsLoading] = React.useState(false);

  const updateStat = (data) => {
    setSelectedOrder(data?.order_id);
    setVisible2(true);
  };

  React.useEffect(() => {
    if (!userLoginData?.id) {
      navigate("/sign-in");
    }
  });

  React.useEffect(() => {
    const query = {
      search: searchText,
    };

    AdminApis.getAllPurchase()
      .then((response) => {
        const orders = response?.data?.data;
        if (Array.isArray(orders)) {
          setUserList(orders); // Safely set the array
        } else if (orders && typeof orders === "object") {
          setUserList(Object.values(orders)); // Convert object to array
        } else {
          setUserList([]); // Fallback to an empty array
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        toast.error("Failed to fetch purchase data.");
      });
  }, [searchText]);

  const updateSinglePurchase = (e) => {
    e.preventDefault();

    if (!selectedOrder || !orderStatus) {
      toast.error("Please select an order and a valid status.");
      return;
    }
    const payload = {
      order_id: selectedOrder,
      status: orderStatus,
    };
    setIsLoading(true);
    AdminApis.updateSinglePurchase(selectedOrder, payload)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Order status updated successfully!");

          // Update the local state for immediate UI feedback
          setUserList((prevLists) =>
            prevLists.map((order) =>
              order.order_id === selectedOrder
                ? { ...order, order_status: orderStatus }
                : order
            )
          );
          // Reset form and modal visibility
          setVisible2(false);
          setOrderStatus("");
          setSelectedOrder(null);
        } else {
          toast.error("Failed to update order status.");
        }
      })
      .catch((error) => {
        console.error("Update API Error:", error);
        toast.error("An error occurred while updating the order status.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };


  return (
    <>
      <div className="bg-white">
        <div className="py-4 lg:px-10 px-6 ">
          {/* Back button */}
          <button
            type="button"
            onClick={handleBackClick}
            className="flex items-center text-gray-600 mb-4"
          >
            <FaArrowLeft className="mr-2" />
            <span>Back</span>
          </button>
          <h1 className="text-[30px] font-semibold">Purchases</h1>
          <div className="flex justify-end">
            

            {/* <div className='mt-1'>Filter </div> */}
          </div>
<table className="min-w-full table-auto text-sm text-gray-500">
  <thead className="text-xs text-gray-700 bg-gray-50">
    <tr>
      <th scope="col" className="md:px-4 px-3 py-3 text-center">S/N</th>
      <th scope="col" className="md:px-4 px-3 py-3 text-center">Order ID</th>
      {/* <th scope="col" className="md:px-4 px-3 py-3 text-center">Date</th> */}
      <th scope="col" className="md:px-4 px-3 py-3 text-center">Products</th>
      <th scope="col" className="md:px-4 px-3 py-3 text-center">Quantity</th>
      <th scope="col" className="md:px-4 px-3 py-3 text-center">Price</th>
      <th scope="col" className="md:px-4 px-3 py-3 text-center">Total Price</th>
      <th scope="col" className="md:px-4 px-3 py-3 text-center">Status</th>
    </tr>
  </thead>
  <tbody>
    {userLists?.map((datas, index) => {
      const totalPrice = datas?.products?.reduce(
        (sum, product) =>
          sum + parseFloat(product?.price || 0) * parseFloat(product?.quantity || 1),
        0
      );

      return (
        <tr key={index} className="border-b">
          <td className="md:px-4 px-3 py-4 text-center">{index + 1}</td>
          <td className="md:px-4 px-3 py-4 text-center">{datas?.order_id}</td>
          {/* <td className="md:px-4 px-3 py-4 text-center">{datas?.date_of_purchase}</td> */}
          <td className="md:px-4 px-3 py-4 text-center">
            {datas?.products?.map((product, productIndex) => (
              <div key={productIndex}>
                {product?.product_name}
                <FaCartPlus />
                {/* <FaCartPlus 
  color="#0071BC" 
  className="h-5 w-5" 
  onClick={() => handleAddToCart(product, product?.product_id || datas?.product_id)}
/> */}
                                          <NavLink to={`/view-product/${product?.id}`}>
                                                              {/* {product.product_name.slice(0, 15)}... */}
                                                            </NavLink>
              </div>
            ))}
          </td>
          <td className="md:px-4 px-3 py-4 text-center">
            {datas?.products?.map((product, productIndex) => (
              <div key={productIndex}>
                {product?.quantity}
              </div>
            ))}
          </td>
          <td className="md:px-4 px-3 py-4 text-center">
            {datas?.products?.map((product, productIndex) => (
              <div key={productIndex}>
{new Intl.NumberFormat("en-US", { style: "currency", currency: "NGN" }).format(
          product?.price || 0.0  // Fallback to 0 if price is missing
        )}
              </div>
            ))}
          </td>
          <td className="md:px-4 px-3 py-4 text-center">
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "NGN" }).format(totalPrice)}
          </td>
          <td className="md:px-4 px-3 py-4 text-center">
            {datas?.order_status && (
              <b
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  // backgroundColor:
                  //   datas?.order_status === "pending"
                  //     ? "#FFDDBD"
                  //     : datas?.order_status === "paid"
                  //     ? "#C9F0D0"
                  //     : datas?.order_status === "delivered"
                  //     ? "#FFDDBD"
                  //     : datas?.order_status === "rejected"
                  //     ? "#F5BFBF"
                  //     : datas?.order_status === "InStore"
                  //     ? "#E6BDFF"
                  //     : datas?.order_status === "Breakdown"
                  //     ? "#FEF1E8"
                  //     : "#CAD8FA",
                  color:
                    datas?.order_status === "pending"
                      ? "#FF922B"
                      : datas?.order_status === "paid"
                      ? "#51CF66"
                      : datas?.order_status === "delivered"
                      ? "#FF922B"
                      : datas?.order_status === "rejected"
                      ? "#E03130"
                      : datas?.order_status === "InStore"
                      ? "#B24BF1"
                      : datas?.order_status === "Breakdown"
                      ? "#F97316"
                      : "#4979ED",
                  borderRadius: "10px",
                  padding: "2px 10px",
                }}
              >
                {datas?.order_status}
              </b>
            )}
          </td>
        </tr>
      );
    })}
  </tbody>
</table>

          {/* {Array.isArray(userLists) && userLists.length > 0 ? (
            userLists.map((datas, index) => {
              const totalPrice = datas?.products?.reduce(
                (sum, product) =>
                  sum + parseFloat(product?.price || 0) * parseFloat(product?.quantity || 1),
                0
              );
              

              return (
                <div key={index} className="shadow-lg my-3 border rounded-lg">
                  <div
                    onClick={() => setSelectedOrder(datas?.order_id)}
                    className="cursor-pointer px-3 py-2"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col">
                        <p className="text-[14px] font-[500]">
                          Order id: {datas?.order_id}
                        </p>
                        <p className="text-[14px] font-bold">
                          Name: {datas?.customer?.last_name}{" "}
                          {datas?.customer?.first_name}
                        </p>
                        <p className="text-[14px] font-[500]">
                          Phone Number: {datas?.customer?.phone_number}
                        </p>
                        <p className="text-[14px]">
                          Email: {datas?.customer_email}
                        </p>
                      </div>

                      <div>
                        <div className="flex items-center gap-2">
                          {datas?.order_status && (
                            <p onClick={() => updateStat(datas)}>
                              <b
                                style={{
                                  fontWeight: "400",
                                  fontSize: "15px",
                                  // backgroundColor:
                                  //   datas?.order_status === "pending"
                                  //     ? "#FFDDBD"
                                  //     : datas?.order_status === "paid"
                                  //     ? "#C9F0D0"
                                  //     : datas?.order_status === "delivered"
                                  //     ? "#FFDDBD"
                                  //     : datas?.order_status === "rejected"
                                  //     ? "#F5BFBF"
                                  //     : "#CAD8FA",
                                  color:
                                    datas?.order_status === "pending"
                                      ? "#8B0000"
                                      : datas?.order_status === "paid"
                                      ? "#228B22"
                                      : datas?.order_status === "delivered"
                                      ? "#32CD32"
                                      : datas?.order_status === "rejected"
                                      ? "#8B0000"
                                      : "#4979ED",
                                  borderRadius: "10px",
                                  padding: "2px 10px",
                                }}
                              >
                                {datas?.order_status}
                              </b>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedOrder === datas?.order_id && (
                    <div className="md:mx-7 mt-3">
                      <div className="flex justify-between">
                      <h3 className="px-3">Products:</h3>
                      <div className="">
                          <b>Date: </b>
                          {datas?.date_of_purchase}
                        </div>
</div>

                      <table className="text-sm overflow-auto text-gray-500">
                        <thead className="text-xs text-gray-700 bg-gray-50">
                          <tr>
                            <th className="md:px-4 px-3 py-3">S/N</th>
                            <th className="md:px-4 py-3">Product</th>
                            <th className="md:px-4 py-3">Quantity</th>
                            <th className="md:px-4 py-3">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {datas?.products?.map((product, productIndex) => (
                            <tr key={productIndex} className="py-1">
                              <td className="md:px-4 text-center px-3 py-4">
                                {productIndex + 1}
                              </td>
                              <td className="md:px-4 text-center py-4">
                                {product?.product_name}
                              </td>
                              <td className="md:px-4 text-center py-4">
                                {product?.quantity}
                              </td>
                              <td className="md:px-4 text-center py-4">
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "NGN",
                                }).format(product?.price || 0.0)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="md:flex justify-between px-3 mt-6 mb-2">
                        <div className="">
                          <b>Address: </b>
                          {datas?.customer?.address}
                        </div>

                        <div className="md:mt-0 mt-2">
                          <b>Total Price: </b>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "NGN",
                          }).format(totalPrice)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <p>No orders available</p>
          )} */}

      
       
        </div>
      </div>

   

      <section>
        <Modal
          visible={visible2}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => setVisible2(false)}
        >
          <div className=" " style={{ height: "100%", overflow: "auto" }}>
            <span className="flex justify-end pr-2 pt-2">
              <p
                className="cursor-pointer font-bold"
                onClick={() => setVisible2(false)}
              >
                <SvgElement type={icontypesEnum.CANCEL} />
              </p>
            </span>
            <div className="bg-[#fff] items-center rounded-lg p-1 px-4">
              <div className="">
                <span>Update Purchase</span>
                <form onSubmit={updateSinglePurchase}>
                  <div className="grid md:grid-cols-1 gap-2">
                    <div>
                      <label
                        htmlFor="order_status"
                        className="block mb-2 mt-6 text-sm text-gray-900 dark:text-gray-600"
                      >
                        Select Status
                      </label>
                      <select
                        id="order_status"
                        required
                        value={orderStatus}
                        onChange={(e) => setOrderStatus(e.target.value)}
                        className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer"
                      >
                        <option value="">Select Status</option>
                        <option value="paid">Paid</option>
                        <option value="pending">Pending</option>
                        <option value="delivered">Delivered</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    </div>
                  </div>
                  <span className="flex justify-center pt-8">
                    <button
                      type="submit"
                      disabled={isLoading}
                      style={{
                        backgroundColor: "#0071BC",
                        borderRadius: "50px",
                      }}
                      className="text-white hover:bg-[#0071BC] focus:ring-4 focus:outline-none focus:ring-[#0071BC] font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center"
                    >
                      {isLoading ? "Updating..." : "Update"}
                    </button>
                  </span>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default WitdrawalRequest;

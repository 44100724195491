import React, { useEffect } from "react";

// components

import CardPurchaseList from "../Cards/CardPurchaseList";
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";

export default function PurchaseList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Sidebar title="Purchase List" />
      <div className="relative md:ml-36 bg-white">
        <AdminNavbar title="Purchase List" />
        <div className="flex flex-wrap md:mt-1 mt-4">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-1">
            <CardPurchaseList />
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import AdminNavbar from '../Navbars/AdminNavbar'
import Sidebar from '../Sidebar/Sidebar'
import CreateCardDeals from '../Cards/CreateCardDeals'

const CreateDeals = () => {
  return (
    <div>
         <Sidebar title="Create Store"/>
        <div className="relative md:ml-36 bg-white">
        <AdminNavbar title="Create Store"/>
        <div className="flex flex-wrap md:mt-4 mt-1">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
            <CreateCardDeals />
          </div>
        </div>
        </div> 
    </div>
  )
}

export default CreateDeals
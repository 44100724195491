import React, { useEffect, useState } from 'react';
import { ProductsLiveApi } from '../apis/live/productsLiveApis';

interface Deal {
  name: string;
  description: string;
}

const Deals = () => {
  const [deals, setDeals] = useState<Deal[]>([]);  
  const [status, setStatus] = useState<"idle" | "loading" | "failed">("idle");
  const [error, setError] = useState<string | null>(null); 

  useEffect(() => {
    const fetchDeals = async () => {
        setStatus('loading')
      try {
        const hotdealApi = new ProductsLiveApi ();
        const response = await  hotdealApi.fetchHotDeals();
        setDeals(response.data.deals); 
        setStatus('idle');
      } catch (err) {
        setError('Failed to fetch deals');
        console.log('fetch error in fetching deals', err)
        setStatus('failed')
      } 
    };

    fetchDeals();
  }, []); 

  if (status === "loading") return <div></div>;
  if (status === "failed") return <div>Error in fetching deals {error}</div>;

  return (
    <div className='p-4 mt-10'>
                <h3 className="font-bold text-3xl mb-5">Hot Deals</h3>
      {deals.length > 0 ? (
        <ul>
          {deals.map((deal, index) => (
            <li key={index}>
              <h3>{deal.name}</h3> 
              <p>{deal.description}</p> 
            </li>
          ))}
        </ul>
      ) : (
        <div>No deals available</div>
      )}
    </div>
  );
};

export default Deals;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

const stateSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("authToken"); 
    },
  },
});

export const { setUser, logout } = stateSlice.actions;
export default stateSlice.reducer;

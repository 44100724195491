
import React, { useState, useRef, useEffect} from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../reducer/loginSlice'
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { AuthLiveApis } from "../../apis/live/authLiveApis";
import { AuthApis } from "../../apis/authApis";
import { store } from "../../store/store";
import { History } from "history";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../Navbars/Navbar";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import Modal from 'react-awesome-modal';
import { PhoneInput } from "react-contact-number-input";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { FaTrash, FaEdit } from "react-icons/fa";
import { AiOutlineWarning } from "react-icons/ai";
import { useLoadScript, StandaloneSearchBox, LoadScriptProps } from '@react-google-maps/api';




const libraries= ["places"];



// components



export default function CardCreateLink() {
  const navigate = useNavigate();
  const userLoginData = useSelector((state) => state.data.login.value);

  let [visible, setVisible] = React.useState(false);
  let [value, setvalue] = React.useState(false);
  const [data, setData] = useState('');

  const [email, setEmail] = useState(data?.email);
  const [phoneNumber, setPhoneNumber] = useState(data?.phone_number);
  const [firstName, setFirstName] = useState(data?.first_name);
  const [lastName, setLastName] = useState(data?.last_name);
  const [address, setAddress] = useState(data?.address);

  function toggleModal() {
    setVisible(!visible)
  }


  React.useEffect(() => {
    if (!userLoginData?.id) {
      navigate('/sign-in');
    }
    AdminApis.getProfile().then(
      (response) => {
        if (response?.data) {
          setData(response?.data?.data)
          setEmail(response?.data?.data?.email)
          setPhoneNumber(response?.data?.data?.phone_number)
          setFirstName(response?.data?.data?.first_name)
          setLastName(response?.data?.data?.last_name)
          setAddress(response?.data?.data?.address)
        }

      }
    )
  }, []);

  let [toggle, setToggle] = React.useState(false);
  function togglePasswordModal() {
    setToggle(!toggle)
  }

  function toggleCloseModal() {
    setVisible(!visible)
    navigate('/mylinks');
  }

  function isCopied() {
    toast.success("Copied to Clipard");
  }

  const [country, setCountry] = useState('');

  // How to access the redux store





  // This is used to update the store
  const dispatch = useDispatch();

  const changeHandler = value => {
    console.log(value?.label)
    setCountry(value)
  }

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData()
      formData.append('first_name', firstName)
      formData.append('last_name', lastName)
      formData.append('phone_number', phoneNumber)
      formData.append('address', address)
      formData.append('email', email)

      AdminApis.updateProfile(formData).then(
        (response) => {
          if (response?.data) {
            toast.success(response?.data?.message);
          }

        }
      ).catch(function (error) {
        toast.error("Please, update all fields");
      })
    },
    [email, firstName, phoneNumber, lastName, address, country, data]
  );


  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA8uPmEdNIXnG7TXLMf2iECVp5wAmXczeY', // Replace with your actual API key
    libraries,
});


const [suggestions, setSuggestions] = useState([]);
const searchBoxRef = useRef(null);

if (!isLoaded) return <div></div>;
if (loadError) return <div>Error loading </div>;

const handleLoad = (searchBox) => {
    searchBoxRef.current = searchBox;
};

const handlePlacesChanged = () => {
    const places = searchBoxRef.current?.getPlaces();
    if (places && places.length > 0) {
        setAddress(places[0].formatted_address || '');
        setSuggestions([]);
    }
};

const handleInputChange = async (e) => {
    const input = e.target.value;
    setAddress(input);

    if (input.length > 2) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
              setSuggestions(predictions);
          }
      });
  } else {
      setSuggestions([]);
  }
};





  return (
    <>

      <div className="pb-32 mt-3 sm:px-10">
        <div className="container  px-6 borde">

          <div className=" text-lg font-[700] bg-[#0071BC] text-white p-3 rounded mt-20">
            <span className="font-semibold text-xl">Account Overview</span>
          </div>
        <div className="border px-5 pb-5">

 
          <div className="grid xl:grid-cols-2 gap-5 mt-5">
              <div className="border rounded shadow-lg">
                <div className="p-5">
                <h1 className="font-medium text-lg">ACCOUNT DETAILS</h1>
                    </div>
                <hr/>
                  <div className="lg:flex flex-row mt-5 lg:space-x-10 p-5">
                <div className="flex">
                {data?.profile_picture ? (
                  <img src={data.profile_picture} alt="profile" />
                ) : (
                  <div className="bg-[#0071BC] text-white text-3xl rounded-full w-28 h-28 flex items-center justify-center">
                    {data?.first_name?.charAt(0)}{data?.last_name?.charAt(0)}
                  </div>
                )}
                </div>
                <div className="flex flex-col  justify-center">
                <p className="text-base font-medium text-black">{data?.first_name} {data?.last_name}</p>
                <p className="font-medium text-base text-gray-500">{data?.email}</p>
                  </div>
                </div>
              </div>

                <div className="border p- shadow-lg rounded">
                  <div className="p-5">
                <h1 className="font-medium text-lg">ADDRESS BOOK</h1>
                    </div>
                <hr/>
                <div className="space-y-3 mt-5 px-5">
                  <p className="font-semibold text-base">Your default shipping address: </p>
                  <p className="text-base font-normal  lg:flex flex-row pt-3 gap-2"> <span><h4 className="text-gray-500 font-b=normal text-base">Your name: </h4></span> {data?.first_name} {data?.last_name}</p>
                <p className="text-base font-normal  lg:flex flex-row gap-2"><span><h4 className="text-gray-500 font-b=normal text-base ">Address: </h4></span>{data?.address}</p>
                <p className="text-base font-normal lg:flex flex-row gap-2"><span><h4 className="text-gray-500 font-b=normal text-base">Phone Number: </h4></span>{data?.phone_number}</p>
                </div>
                </div>
                </div>
                {/* <div className="px-5">
                <h1>Edit profile</h1>
                    </div>
                    <hr/> */}
          <form className="pb-10 mt-8  rounded shadow-lg border" onSubmit={handleSubmit}>
            <div className="flex justify-between">
            <h1 className="font-medium text-lg p-3">Edit Profile</h1>
              <FaEdit className="w-5 h-5 mr-5 mt-3" color="#0071bc"/>
            </div>
            <hr/>
            <div class="grid gap-6 mb-6 md:grid-cols-2 mt-5 px-5 pt-5">
              <div>
                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 ">First Name</label>
                <input type="text" name="first_name" id="first_name" defaultValue={data?.first_name} onChange={(e) => setFirstName(e?.target?.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Name" />
              </div>

              <div>
                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 ">Last Name</label>
                <input type="text" name="last_name" id="" defaultValue={data?.last_name} onChange={(e) => setLastName(e?.target?.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Name" />
              </div>
              <div>
                <label class="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                <input disabled type="email" name="email" onChange={(e) => setEmail(e?.target?.value)} defaultValue={data?.email} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Email" />
              </div>
              <div>
                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 ">Phone</label>
                <input type="text" defaultValue={data?.phone_number} onChange={(e) => setPhoneNumber(e?.target?.value)} id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Phone" />
              </div>
              <div>
                <label for="countries" class="block mb-2 text-sm font-medium text-gray-900">Gender</label>
                <select disabled defaultValue={data?.gender} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                  <option selected value={data?.gender}>{data?.gender}</option>
                  <option value="personal">Personal</option>

                </select>
              </div>
              <div>
                <label for="countries" class="block mb-2 text-sm font-medium text-gray-900">Country</label>
                {/* <Select options={options} value={country} onChange={changeHandler} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" /> */}

                <select disabled onChange={(e) => setCountry(e?.target?.value)} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                  <option value="Nigeria">Nigeria</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Aland Islands">Aland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                  <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">Central African Republic</option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, Democratic Republic of the Congo">Congo, Democratic Republic of the Congo</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curacao">Curacao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">French Southern Territories</option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-Bissau">Guinea-Bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                  <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kosovo">Kosovo</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                  <option value="Moldova, Republic of">Moldova, Republic of</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">Netherlands Antilles</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Barthelemy">Saint Barthelemy</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Martin">Saint Martin</option>
                  <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                  <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Sint Maarten">Sint Maarten</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                  <option value="South Sudan">South Sudan</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                  <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-Leste">Timor-Leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">United Arab Emirates</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">Virgin Islands, British</option>
                  <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </div>


            </div>

            <div className="relative w-full lg:w-12/12 px-5">
              <StandaloneSearchBox
                onLoad={handleLoad}
                onPlacesChanged={handlePlacesChanged}
              >
                {/* <div className=""> */}
                <div className="relative ">
                  <label className="block mb-2 text-sm font-semibold text-[#414143]">
                    Address
                  </label>

                  <textarea
                    className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    placeholder="Enter Delivery Address"
                    name="address"
                    required
                    value={address}
                    onChange={(e) => handleInputChange(e)}
                  // placeholder="Enter your address"
                  // onChange={() => handleChange}

                  />

                </div>
               
              </StandaloneSearchBox>
              {suggestions.length > 0 && (
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.place_id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        setAddress(suggestion.description);
                        setSuggestions([]);
                      }}
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>



            <span className="md:flex md:justify-start mt-4 gap-2 px-5">
              <button
                type="submit"
                style={{ backgroundColor: '#0071BC', borderRadius: '50px' }}
                className="text-sm w-full sm:w-auto px-10 py-2.5 text-center text-white hover:bg-[#0071BC] focus:ring-4 focus:outline-none focus:ring-[#0071BC] font-medium rounded-lg  "
              >
                Update
              </button>

              {/* <p className=" text-gray-500 flex justify-center pt-3 text-xs font-xs cursor-pointer" onClick={toggleModal}>Cancel</p> */}

            </span>
            {/* <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button> */}
          </form>
          </div>


          {/* <form onSubmit={updatePassword}>
            <span className="flex gap-3 pb-5 cursor-pointer" onClick={togglePasswordModal}>
              <label for="email" class="block mb-2 text-sm text-gray-900">Change Password </label>
              <span><SvgElement type={icontypesEnum.ARROWUP} /></span>
            </span>

            <div className={toggle ? '' : 'hidden'}>
              <div className="grid gap-6 mb-2 md:grid-cols-2">
                <div class="mb-6">
                  <label for="password" class="block mb-2 text-sm text-gray-500 font-bold">Current Password</label>
                  <input onChange={(e) => setOldPassword(e?.target?.value)} type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="•••••••••" required="" />
                </div>
              </div>

              <div className="grid gap-6 mb-2 md:grid-cols-2">
                <div class="mb-6">
                  <label for="password" class="block mb-2 text-sm font-bold text-gray-500 ">New Password</label>
                  <input onChange={(e) => setPassword(e?.target?.value)} type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="•••••••••" required="" />
                </div>
              </div>

              <div className="grid gap-6 mb-2 md:grid-cols-2">
                <div class="mb-6">
                  <label for="password" class="block mb-2 text-sm font-bold text-gray-500 ">Confirm Password</label>
                  <input onChange={(e) => setPasswordConfirmation(e?.target?.value)} type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="•••••••••" required="" />
                </div>
              </div>

              <button
                type="submit"
                style={{ backgroundColor: '#0071BC', borderRadius: '50px' }}
                className="text-sm w-full sm:w-auto px-10 py-2.5 text-center text-white hover:bg-[#0071BC] focus:ring-4 focus:outline-none focus:ring-[#0071BC] font-medium rounded-lg  "
              >
                Update
              </button>
            </div>
          </form> */}



        </div>

      </div>

      {/* <Doughnut data={...} /> */}




      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}

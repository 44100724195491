import { AxiosPromise } from "axios";
import configs from "../../configs";
import { store } from "../../store/store";
import { ProductApiResponse, FrequentlyPurchasedResponse, HotDealResponse } from "../../types/products";
import { AxiosGlobal } from "../shared/axios";



export class ProductsLiveApi extends AxiosGlobal{
    
    getAllProducts(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/market-links/get-market-products?page=${pageNo}&search=${encodeURIComponent(query?.search)}&location=${encodeURIComponent(query?.name)}&categories=${encodeURIComponent(query?.categories)}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }


    getAllDeals(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/market-links/get-all-deals?page=${pageNo}&search=${query?.search}&location=${query?.name}&categories=${query?.categories}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    fetchSuggestions(searchTerm: string): AxiosPromise<Array<any>>{
        // if (!searchTerm) return Promise.resolve([]); // Return empty array if no search term
        return this.axios.get(`${configs.context}/search-suggestions`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${store.getState().data.login.value.token}`,
            "Access-Control-Allow-Origin": "*",
          },
          params: {
            query: searchTerm,
            per_page: 10,
            page: 1,
          },
        });
      };
     

     fetchNewData(): AxiosPromise<ProductApiResponse> {
      return this.axios.get('https://prod.mmartplus.com/api/frontend/products',{
        headers: { 
          "Content-Type": "aplication/json","Accept":"aplication/json",
          "Access-Control-Allow-Origin":"*" },
      })
    }

     fetchFrequentlyPurchased(): AxiosPromise<FrequentlyPurchasedResponse> {
      return this.axios.get('https://prod.mmartplus.com/api/products/frequently-purchased',{
        headers: { 
          "Content-Type": "aplication/json","Accept":"aplication/json",
          "Access-Control-Allow-Origin":"*" },
      })
    }
     fetchHotDeals(): AxiosPromise<HotDealResponse> {
      return this.axios.get('https://prod.mmartplus.com/api/market-links/get-all-deals',{
        headers: { 
          "Content-Type": "aplication/json","Accept":"aplication/json",
          "Access-Control-Allow-Origin":"*" },
      })
    }

    // fetchCoupon(): AxiosPromise<ProductApiResponse> {
    //   return this.axios.get('https://prod.mmartplus.com/api/fronted-coupons', {
    //     headers: {
    //       "Content-Type": "aplication/json","Accept":"aplication/json",
    //       "Access-Control-Allow-Origin":"*" 
    //     }
    //   })
    // }

    // getCoupon(): AxiosPromise<ProductApiResponse> {
    //   return this.axios.post('https://prod.mmartplus.com/api/apply-coupon', {
    //     headers: {
    //       "Content-Type": "aplication/json","Accept":"aplication/json",
    //       "Access-Control-Allow-Origin":"*" 
    //     }
    //   })
    // }
}